import React from 'react';
import { Outlet } from 'react-router-dom';
import { PageContainer } from './styles';

export function USLayout() {
  return (
    <PageContainer>
      <Outlet />
    </PageContainer>
  );
}

import axios from 'axios';
import { ANALYTICS_APP_EXTERNAL, endpoints } from './constants';

export const getTermsAndConditionsData = async (data: { account: string; jwt: string }) => {
  const getUrlWithParams = () =>
    `${ANALYTICS_APP_EXTERNAL}/${endpoints.GET_MERCHANT_DATA_BY_KEYS}?account=${data.account}&jwt=${data.jwt}`;

  return await axios
    .get(getUrlWithParams(), {})
    .then(response => response.data)
    .catch(error => {
      console.log(error);
    });
};

import React, { ReactElement, ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getStatus } from '../store/termsAndConditions/termsAndConditionsSlice';
import { STATE_STATUS } from '../store/constants';

interface SpinnerProps {
  size?: number;
}

const generateElsForSpinner = () => {
  const res = [];
  for (let i = 0; i < 12; i += 1) {
    res.push(`&:nth-child(${i + 1}){ transform: rotate(${i * 30}deg); animation-delay: -${1.1 - 0.1 * i}s;}`);
  }
  return res.join('');
};

const getSize = (props: SpinnerProps) => props.size || 50;

const SpinnerContainer = styled.div<SpinnerProps>`
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  div {
    transform-origin: ${props => getSize(props) / 2}px ${props => getSize(props) / 2}px;
    animation: lds-spinner 1.2s linear infinite;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 1px;
      left: ${props => getSize(props) / 2 - 2}px;
      width: ${props => getSize(props) / 12}px;
      height: ${props => getSize(props) / 4}px;
      border-radius: 20%;
      background: rgba(0, 0, 0, 0.2);
    }
    ${generateElsForSpinner()};
  }
`;

const Spinner = (props: SpinnerProps) => (
  <SpinnerContainer {...props}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </SpinnerContainer>
);

const LoaderComponent = styled.div<SpinnerProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(256, 256, 256, 0.7);
  z-index: 98;
  & > * {
    margin: 10px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

interface LoaderProps {
  size?: number;
  children: ReactNode;
}

export const PageWithLoader = (props: LoaderProps): ReactElement => {
  const requestStatus = useSelector(getStatus);
  const isLoading = useMemo(() => requestStatus === STATE_STATUS.PENDING, [requestStatus]);
  return (
    <>
      {isLoading ? (
        <LoaderComponent>
          <Spinner size={props.size} />
        </LoaderComponent>
      ) : (
        props.children
      )}
    </>
  );
};

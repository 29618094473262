import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { ReactComponent as Icon } from '../icons/errorIcon.svg';

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Roboto', Arial, sans-serif;
`;

const ContentContainer = styled.div`
  margin-top: -200px;
  padding: 15px;
  font-size: 20px;
  font-weight: normal;
  color: rgb(84, 87, 90);

  p {
    margin: 0.5em 0;
  }
`;

const StyledIcon = styled(Icon)`
  margin-bottom: 15px;
`;

export const ErrorPage = (props: { account: string | null }): ReactElement => {
  const { account } = props;

  const message = {
    'mygp.cz': 'Omlouváme se, došlo k chybě.',
    'mygp.sk': 'Prepáčte, vyskytla sa chyba.',
    'mygp.at': 'Wir bitten um Entschuldigung, es ist ein Fehler passiert.',
    'mygp.ro': 'Ne pare rău, a apărut o eroare.'
  };
  const defaultMessage = 'Sorry, an error has occurred.';

  const accounts = Object.keys(message);

  const errorText = account && accounts.includes(account) ? message[account] : defaultMessage;
  return (
    <PageContainer>
      <ContentContainer>
        <div>
          <StyledIcon />
        </div>
        <p>{errorText}</p>
      </ContentContainer>
    </PageContainer>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TranslationNamespace, changeLanguage } from 'i18n';
import { RootState } from 'store/appStore';
import { optOut } from 'store/email-marketing/opt-out';
import {
  ButtonContainer,
  Checkbox,
  CheckboxGroup,
  CheckboxLabel,
  ConfirmationBox,
  ConfirmationText,
  ConfirmationTitle,
  SubmitButton
} from '../styles';

export function Confirmation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation(TranslationNamespace.EmOptout);
  const optOutStatus = useSelector((state: RootState) => state.optOut.emailMarketing.status);
  const [searchParams] = useSearchParams();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const locale = searchParams.get('locale');
  const trackingCode = searchParams.get('trackingCode');
  const account = searchParams.get('account');

  const title = t('EmOptout:confirmation.title', 'Are you sure you want to unsubscribe from Email?');
  const text = t(
    'EmOptout:confirmation.bodyText',
    'By checking the box and confirmation button below, you are giving positive consent that you no longer want to receive email marketing messages from this merchant. Are you sure you want to unsubscribe from email marketing messages?'
  );
  const checkboxText = t(
    'EmOptout:confirmation.checkboxText',
    'I agree and confirm that I want to unsubscribe from email marketing messages.'
  );
  const btnText = t('EmOptout:confirmation.btnText', 'Submit');

  const onCheckboxClick = useCallback((event: any) => setIsConfirmed(event.target.checked), []);
  const onSubmitClick = useCallback(
    // @ts-ignore
    (event: any) => dispatch(optOut({ trackingCode })),
    [dispatch, trackingCode]
  );

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    changeLanguage(locale || '');
  }, [locale]);

  useEffect(() => {
    if (optOutStatus === 'initial') return;
    navigate(`status?account=${account}&locale=${locale}`);
  }, [navigate, optOutStatus, account, locale]);

  return (
    <ConfirmationBox>
      <ConfirmationTitle>{title}</ConfirmationTitle>
      <ConfirmationText>{text}</ConfirmationText>
      <CheckboxGroup>
        <Checkbox type="checkbox" name="confirm-checkbox" onClick={onCheckboxClick} />
        <CheckboxLabel htmlFor="confirm-checkbox">{checkboxText}</CheckboxLabel>
      </CheckboxGroup>
      <ButtonContainer>
        <SubmitButton disabled={!isConfirmed} onClick={onSubmitClick}>
          {btnText}
        </SubmitButton>
      </ButtonContainer>
    </ConfirmationBox>
  );
}

import axios from 'axios';
import { OptOutResponse } from 'store/email-marketing/opt-out';
import { CRM_PROC_URL } from './constants';

const client = axios.create({ baseURL: CRM_PROC_URL });

export const crmProcClient = {
  unsubscribeContact: (trackingCode: string) =>
    client.get<OptOutResponse>('/smbBundle/unsubscribe', { params: { trackingCode } })
};

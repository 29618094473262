import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, selectMerchantInfo } from '../../../../store/termsAndConditions/termsAndConditionsSlice';
import { PrivacyPolicy } from './BroomfieldPrivacyPolicy';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { PageWithLoader } from '../../../PageWithLoader';

export const BroomfieldPrivacyPolicy = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const merchantInfo = useSelector(selectMerchantInfo);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  useEffect(() => {
    const account = searchParams.get('account');
    const jwt = searchParams.get('jwt');

    if (account && jwt) {
      dispatch(fetchData({ account, jwt }));
    }
  }, [dispatch, searchParams, setSearchParams]);

  return (
    <PageWithLoader>
      <PrivacyPolicy {...merchantInfo} />
    </PageWithLoader>
  );
};

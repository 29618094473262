import React, { useEffect } from 'react';
import { fetchData, selectMerchantInfo } from '../../../../store/termsAndConditions/termsAndConditionsSlice';
import {
  TermsTextWrapper,
  TermsPlainTextWrapper,
  TermsSubTitle,
  TermsPlainText,
  Paragraph,
  TermsTitle,
  SubParagraph
} from '../../../styles';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { PageWithLoader } from '../../../PageWithLoader';

export const BroomfieldLoyaltyTerms = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { merchantName, pointThreshhold, rewardAmount, privacyPolicyUrl } = useSelector(selectMerchantInfo);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  useEffect(() => {
    const account = searchParams.get('account');
    const jwt = searchParams.get('jwt');

    if (account && jwt) {
      dispatch(fetchData({ account, jwt }));
    }
  }, [dispatch, searchParams, setSearchParams]);

  const title: string = 'CONDITIONS FOR PARTICIPATION IN THE LOYALTY PROGRAM';

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <PageWithLoader>
      <TermsTextWrapper>
        <TermsPlainTextWrapper>
          <TermsTitle>{title}</TermsTitle>
          <TermsPlainText>
            The following Terms of Service (“Terms” or “Terms of Service”) are a legal agreement between you (“you” or
            “your”) and Global Payments Direct, Inc. (&quot;Global Payments&quot;, &quot;we&quot;, “us”, or “our”) and
            govern your access to and use of the Global Payments loyalty program, which includes any related websites or
            applications, or any other ways Global Payments allows you to engage with us directly or through a Merchant
            that uses Global Payments point-of-sale (“POS”) solutions that enable our business customers (each a
            “Merchant”, and collectively, “Merchants”) to process credit and debit payments and perform a variety of
            back-office functions. As part of those payment processing solutions, our POS system includes services
            designed to support Merchants in offering the following to you a loyalty and other benefits program (a
            “Loyalty Program”) By accessing and/or using the Loyalty Program, you agree to these Terms and any other
            policies or terms referenced within or posted throughout the Loyalty Program, including but not limited to
            promotion terms, guidelines, as well as any rules or terms applicable to particular features or promotions,
            which are hereby expressly incorporated into these Terms by reference. You also acknowledge that you have
            read and understand our privacy statement (the <a href={privacyPolicyUrl}>Privacy Statement</a>).
          </TermsPlainText>
          <TermsPlainText>
            FROM TIME TO TIME WE MAY UPDATE OR MODIFY THESE TERMS OF SERVICE IN OUR DISCRETION, AND WILL POST THE
            UPDATED TERMS TO {merchantName ?? '[organisation name]'}. THE UPDATED TERMS OF SERVICE WILL BECOME EFFECTIVE
            AS OF THE EFFECTIVE DATE INDICATED IN THE TERMS OF SERVICE (“EFFECTIVE DATE”). ANY USE OF THE LOYALTY
            PROGRAM AFTER THE EFFECTIVE DATE MEANS YOU HAVE ACCEPTED THE UPDATED TERMS. YOUR SOLE AND EXCLUSIVE REMEDY
            IN THE EVENT YOU DO NOT ACCEPT THE UPDATED TERMS OF SERVICE IS TO CEASE YOUR ACCESS TO AND USE OF THE
            LOYALTY PROGRAM.
          </TermsPlainText>

          <TermsPlainText>
            THESE TERMS INCLUDE AN AGREEMENT TO RESOLVE DISPUTES BY ARBITRATION, WHICH MEANS THAT YOU AND GLOBAL
            PAYMENTS WAIVE THE RIGHT TO A TRIAL BY JURY AND AGREE TO RESOLVE ANY AND ALL DISPUTES THROUGH BINDING
            ARBITRATION ON AN INDIVIDUAL BASIS.
          </TermsPlainText>

          <TermsPlainText>
            If you are an individual, you must be at least 18 years of age, a U.S. or Canadian resident, and legally
            capable of entering into contracts. If you are an entity, organization, or company, or acting on behalf of
            one, you represent and warrant that you are an authorized representative of such entity, and that you have
            the authority to and agree to bind it to these Terms. You represent that you will not use the Loyalty
            Program, directly or indirectly, for any fraudulent undertakings.
          </TermsPlainText>

          <TermsSubTitle>1. LOYALTY PROGRAM DESCRIPTION</TermsSubTitle>

          <TermsPlainText>
            Global Payments provides technology services that enable Merchants to offer loyalty and/or rewards programs
            to Merchant customers. By enrolling in the Loyalty Program offered by Global Payments or by a Merchant using
            Global Payments’ services, you expressly agree to these program terms.
          </TermsPlainText>

          <Paragraph>
            a. You may enroll in a Merchant’s loyalty program by signing up at the Merchant or while placing an order,
            subject to the Merchant’s terms and conditions related to its loyalty program. Once you have enrolled, you
            may earn points when you make purchases from Merchants, which can later be redeemed for discounts or other
            benefits. If you initiate a return, chargeback, or refund, points associated with the purchase may be
            revoked. You may check your current rewards balance by clicking the link you receive when you sign up for a
            Merchant’s loyalty program.
          </Paragraph>

          <Paragraph>
            b. If you choose to enroll in a Merchant’s loyalty program, you agree that the Merchant may contact you
            using the contact method(s) you provide with program information, offers, and other marketing and
            transactional communications. Any information shared by Global Payments with Merchants is subject to our
            Privacy Statement. Once a Merchant has received information you provide from Global Payments, the use and
            governance of that information will be managed by the Merchant, which may have separate privacy practices
            and policies. Global Payments does not authorize Merchants to send marketing messages to your mobile number.
            Merchants may not send marketing messages to your mobile number unless you expressly authorize the Merchant
            to send you such messages.
          </Paragraph>
          <Paragraph>
            c. Each Merchant is solely responsible for the operation and maintenance of its own loyalty program,
            including establishing the number of points that you may earn when you make purchases, setting redemption
            thresholds, and determining how and when points may be redeemed for rewards. Merchants have the ability to
            modify their loyalty programs and/or to modify the points balance held by you or any individual Merchant
            customer. Global Payments is not responsible for notifying you in the event that the Merchant changes any
            element of its loyalty program or ceases to offer a loyalty program. The Merchant is solely responsible for
            communicating program or points balance changes to you.
          </Paragraph>
          <Paragraph>
            d. Points earned through any loyalty program offered by Global Payments or a Merchant using Global Payments
            have no cash value and cannot be transferred, redeemed, or sold for cash. Subject to notice requirements
            under Applicable Law and Rules, Global Payments reserves the right, in its sole discretion and at any time,
            to modify, suspend, terminate, revalue or cancel its own loyalty program and/or the technology services that
            enable Merchants to offer loyalty programs through Global Payments. In the event the Loyalty Program offered
            by Global Payments is terminated by the Merchant, Global Payments will notify you of such termination and
            you will have up to 30 days to use any points or rewards you earned. Global Payments reserves the right to
            adjudicate any discrepancies or disputes regarding rewards allocations or these program terms in its sole
            discretion and you agree to abide by any such adjudication.
          </Paragraph>
          <Paragraph>
            e. Purchases and payment transactions you make through the Loyalty Program are transactions between you and
            the Merchant only, not with Global Payments or any of our affiliates. Global Payments is not the seller of
            any product or service offered by Merchants and is not a party to any payment transaction facilitated
            through the Loyalty Program.
          </Paragraph>
          <Paragraph>
            f. The Merchant reserves the right to terminate, modify or change the Loyalty Program upon thirty (30)
            days&lsquo; notice, and in the event of a change in the law, without complying with such notice.
          </Paragraph>
          <Paragraph>
            g. If Global Payments ceases to provide the technical solution through which Merchant operates the Loyalty
            Program without prior notice for reasons beyond Merchant&lsquo;s reasonable control, Merchant may terminate
            the Loyalty Program with immediate effect. In such case, the Merchant customer shall lose all loyalty points
            and benefits associated with participation in the Loyalty Program.
          </Paragraph>
          <Paragraph>
            h. The Merchant further reserves the right to amend or supplement these Terms and Conditions, in particular
            if it is in the interest of simplicity and to ensure the secure processing of personal data and loyalty
            points and/or to prevent abuse of the system. The change to the Terms and Conditions will be notified to the
            Merchant customer in advance via email. In case of disagreement with the change, the Merchant customer has
            the right to terminate his/her participation in the Loyalty Program with immediate effect before the date on
            which the relevant changes take effect.
          </Paragraph>

          <TermsSubTitle>1. PARTICIPATION IN THE LOYALTY PROGRAM</TermsSubTitle>

          <Paragraph>
            a. The Loyalty Program allows Merchant&apos;s customers to collect loyalty points when paying by card and
            then redeem the reward corresponding to the collected loyalty points in the manner described in these Terms
            and Conditions. Participation in the Loyalty Program is subject to these terms and conditions
            (&quot;Terms&quot;).
          </Paragraph>
          <Paragraph>
            b. Any natural person who has reached the age of 18 years may become a Loyalty Program Participant. By
            accepting these Terms and Conditions, the person wishing to join the Loyalty Program confirms that he/she is
            18 years of age. Please note that the Terms and Conditions and other documents including related
            communications are in Czech language only.
          </Paragraph>
          <Paragraph>
            c. During the invitation to join the Loyalty Program, the following Terms and Conditions are made available
            to the customer, which the customer must agree to in order to participate in the Loyalty Program, and the
            Privacy Statement, for which the customer must confirm that he/she has read it.
          </Paragraph>
          <Paragraph>
            d. A Merchant customer is also asked to enter his/her mobile phone number, which will be used for
            confirmation that you want to become a member of the Loyalty Program and will be used to identify the
            Merchant customer and will be linked to the Merchant customer&apos;s Loyalty Program account. The Merchant
            customer is not authorized to enter the mobile phone number of another person.
          </Paragraph>
          <Paragraph>
            e. The Merchant customer enters the Loyalty Program at the moment of confirming &quot;Confirm and receive
            benefits&quot; via the payment terminal and from that moment on, the Loyalty Points are loaded within the
            Loyalty Program according to the rules set out in Article 2.
          </Paragraph>
          <Paragraph>
            f. Participation in the Loyalty Program is free of charge and does not imply an obligation to purchase goods
            or services from the Merchant.
          </Paragraph>
          <Paragraph>g. Permitted Activities. By using the Loyalty Program, you agree that:</Paragraph>
          <SubParagraph>
            i. You will not use the Loyalty Program in any way that violates Applicable Law and Rules, these Terms, or
            any other policies.
          </SubParagraph>
          <SubParagraph>
            ii. You will not provide false information about yourself to us, impersonate any other person, collect
            information about other users, or otherwise attempt to mislead others about your identity or the
            truthfulness or accuracy of the data you transmit through the Loyalty Program.
          </SubParagraph>
          <SubParagraph>
            iii. You will not damage, disable, disrupt, overburden, interfere with, or attempt to gain unauthorized
            access to any portion of our Loyalty Program, or any other person’s use and enjoyment of the Loyalty
            Program.
          </SubParagraph>
          <SubParagraph>
            iv. Global Payments may suspend or terminate your use of the Loyalty Program at its sole discretion for any
            actual or suspected violation of these limitations or other applicable policies or rules referenced in these
            Terms of Service.
          </SubParagraph>

          <Paragraph>
            h. Methods of Agreement You acknowledge and agree that by clicking on the “Go”, “Agree”, “Submit”, “Create
            Account” or similar button on the POS device, or other feature offered under our Loyalty Program and
            associated with these Terms or with any other electronic document authorizing Global Payments to provide the
            Loyalty Program to you, you are indicating your intent to sign these Terms and/or other applicable
            agreement(s). You also agree that clicking on these buttons on the POS device shall constitute your
            electronic signature to these Terms or other such document displayed with the button(s) and relating to the
            Loyalty Program. You further agree that your electronic signature on these Terms and any other document
            shall have the same legal effect under state and federal law as if you signed the documents in ink on paper.
          </Paragraph>

          <TermsSubTitle>3. ACCUMULATION AND STATUS OF LOYALTY POINTS</TermsSubTitle>
          <TermsPlainText>
            For each payment under the Loyalty Program at the Merchant&apos;s payment terminal, the Merchant customer
            shall earn one (1) Loyalty Point for each US Dollar of purchase made. The information on the amount of
            loyalty points earned will be displayed on the payment terminals at each payment.
          </TermsPlainText>

          <TermsSubTitle>4. REDEMPTION OF LOYALTY POINTS</TermsSubTitle>
          <Paragraph>
            a. Upon reaching the threshold of {pointThreshhold ?? '[pointThreshhold]'} Loyalty Points, Loyalty Points in
            the amount indicated will be automatically converted to a reward of{' '}
            {rewardAmount ? `${parseInt(rewardAmount)} $` : '[rewardAmount $]'}. From this point onwards, the Merchant
            customer will have earned the reward and will have sixty (60) days to redeem the reward under the Loyalty
            Program.
          </Paragraph>
          <Paragraph>
            b. If the above-mentioned threshold of loyalty points is reached, the Merchant customer will be shown
            information on the Merchant&apos;s POS device that he/she is entitled to a reward.
          </Paragraph>
          <Paragraph>
            c. The amount of loyalty points earned for purchases made at the Merchant shall be revoked in the event that
            the Merchant refunds the funds to the Merchant customer.
          </Paragraph>
          <Paragraph>
            d. Accumulated Loyalty Points are gradually deleted after three hundred sixty-five (365) days from the date
            they were credited under the Loyalty Program (unless previously converted to a reward). In accordance with
            Section 4.a, above, of these Terms and Conditions, a Reward will be deleted sixty (60) days after the date
            on which it could have first been redeemed.
          </Paragraph>

          <TermsSubTitle>5. TERMINATION OF PARTICIPATION IN THE LOYALTY PROGRAM</TermsSubTitle>
          <Paragraph>
            a. If the Merchant customer does not agree to participate in the Loyalty Program, the Merchant customer may
            opt out of the Loyalty Program by informing the Merchant that the Merchant customer desires to terminate its
            participation in the Loyalty Program.
          </Paragraph>
          <Paragraph>
            b. In the event of termination of participation in the Loyalty Program, the Merchant customer will lose all
            loyalty points and benefits associated with participation in the Loyalty Program, and the Merchant
            customer&apos;s data will be removed from the system once the Merchant notifies Global Payments that the
            Merchant customer’s participation in the Loyalty program has been terminated.
          </Paragraph>
          <Paragraph>
            c. In the event of a re-registration for the Loyalty Program, no benefits accrue from the Merchant
            customer&apos;s previous participation.
          </Paragraph>
          <Paragraph>
            d. Merchant may terminate Merchant customer&apos;s account for breach of these Terms upon sixty (60)
            days&apos; notice.
          </Paragraph>
          <Paragraph>
            e. Additional grounds for termination of the Loyalty Program are set forth in Sections 1.f and 1.g of these
            Terms and Conditions.
          </Paragraph>

          <TermsSubTitle>6. GOVERNING LAW AND DISPUTE RESOLUTION</TermsSubTitle>

          <Paragraph>
            <TermsPlainText>a. Governing Law </TermsPlainText>
            <TermsPlainText style={{ marginLeft: 18 }}>
              You agree that any and all disputes or controversies of any nature whatsoever (whether in contract, tort,
              or otherwise) arising out of, relating to, or in connection with (a) these Terms, (b) the relationships
              which result from these Terms, or (c) the validity, scope, interpretation, or enforceability of the choice
              of law and forum provisions of these Terms, shall be governed by the laws of the State of Georgia, United
              States and applicable United States Federal law, notwithstanding any conflicts of laws rule; provided,
              however, that the Federal Arbitration Act (9 U.S.C. § 1 et seq.) governs the interpretation and
              enforcement of Section 6.b when permitted. The United Nations Convention on Contracts for the
              International Sale of Goods and Uniform Computer Information Transactions Act will not apply to these
              Terms of Service.
            </TermsPlainText>
          </Paragraph>
          <Paragraph>
            b. <b>Dispute Resolution – Arbitration</b>
            <Paragraph>
              MANDATORY ARBITRATION: ANY DISPUTE OR CLAIM ARISING OUT OF, RELATING TO, OR IN CONNECTION WITH THIS
              AGREEMENT OR THE RELATIONSHIPS WHICH RESULT FROM THIS AGREEMENT SHALL BE RESOLVED BY BINDING ARBITRATION,
              RATHER THAN IN COURT; HOWEVER, YOU MAY ASSERT CLAIMS IN SMALL CLAIMS COURT IF (1) THE CLAIMS QUALIFY FOR
              SMALL CLAIMS COURT; (2) THE MATTER REMAINS IN SMALL CLAIMS COURT AT ALL TIMES; AND (3) THE MATTER PROCEEDS
              ONLY ON AN INDIVIDUAL (NOT A CLASS OR REPRESENTATIVE) BASIS). ARBITRATION DOES NOT PROCEED BEFORE A JURY
              AND MAY INVOLVE MORE LIMITED DISCOVERY THAN A COURT PROCEEDING. ANY ARBITRATION UNDER THIS AGREEMENT WILL
              ONLY BE ON AN INDIVIDUAL BASIS. CLASS ARBITRATIONS, CLASS ACTIONS, PRIVATE ATTORNEY GENERAL ACTIONS, AND
              CONSOLIDATION WITH OTHER ARBITRATIONS ARE NOT PERMITTED.
            </Paragraph>
            <Paragraph>
              The arbitrator&apos;s award or decision will not affect issues or claims involved in any proceeding
              between Xenial and any person or entity who is not a party to the arbitration. The arbitrator may award
              monetary, declaratory or injunctive relief only in favor of the individual party seeking relief and only
              to the extent necessary to provide relief warranted by that party&apos;s individual claim. The
              arbitrator&apos;s award, if any, will not apply to any person or entity that is not a party to the
              arbitration. However, nothing in this Section or Agreement shall preclude any party from bringing issues
              to the attention of federal, state or local agencies. Such agencies can, if the law allows, seek relief on
              your behalf. Further, notwithstanding the foregoing, nothing in this Section or this Agreement prohibits a
              party from applying to a court of competent jurisdiction for a temporary restraining order, preliminary
              injunction, or other equitable relief.
            </Paragraph>
            <Paragraph>
              The Federal Arbitration Act (9 U.S.C. § 1 et seq.) governs the interpretation and enforcement of the
              arbitration provisions of this section. Arbitration will be administered by JAMS (www.jamsadr.com). For
              claims greater than $250,000, the JAMS Comprehensive Arbitration Rules and Procedures in effect at the
              time the arbitration is commenced will apply (if no such rules are in effect, JAMs default arbitration
              rules shall apply). For claims equal to or less than $250,000, the JAMS Streamlined Arbitration Rules and
              Procedures in effect at the time the arbitration is commenced will apply (if no such rules are in effect,
              JAMS default arbitration rules shall apply). Unless the arbitrator(s) determine that justice or fairness
              require otherwise: (i) any arbitration will proceed in Muscogee County, Georgia (although, for
              convenience, any party or its counsel may participate telephonically); and (ii) the arbitrator(s) will
              oversee limited discovery, taking into account the amount in controversy and the parties’ desire to keep
              proceedings cost-effective and efficient. Any decision rendered in any arbitration proceeding shall be
              final and binding on each of the parties to the arbitration and judgment may be entered thereon in any
              court of competent jurisdiction. The parties will maintain the confidential nature of the arbitration
              proceeding except as may be necessary to enforce any award or to comply with applicable law.
            </Paragraph>
            <Paragraph>
              If the total damage claims in an arbitration are $10,000 or less, not including your attorney fees
              (&quot;Small Arbitration Claim&quot;), the arbitrator may, if the you prevail, award you reasonable
              attorney fees, and costs (separate from Arbitration Costs as defined below), but may not grant Global
              Payments its attorney fees or costs (separate from Arbitration Costs) unless the arbitrator determines
              that your claim was frivolous or brought in bad faith. In a Small Arbitration Claim case, Global Payments
              will pay all arbitration filing, administrative and arbitrator costs (together, &quot;Arbitration
              Costs&quot;). You must submit any request for payment of Arbitration Costs to JAMS at the same time you
              submit your Demand for Arbitration. However, if you want Global Payments to advance the Arbitration Costs
              for a Small Arbitration Claim before filing, Global Payments will do so at your written request which must
              be sent to Global Payments at the address in paragraph 19, below.
            </Paragraph>
            <Paragraph>
              If your total damage claims in an arbitration exceed $10,000, not including your attorney fees
              (&quot;Large Arbitration Claim&quot;), the arbitrator may award the prevailing party its reasonable
              attorneys&apos; fees and costs, or it may apportion attorneys&apos; fees and costs between you and Global
              Payments (such fees and costs being separate from Arbitration Costs). In a Large Arbitration Claim case,
              if you are able to demonstrate that the Arbitration Costs will be prohibitive as compared to the costs of
              litigation, Global Payments will pay as much of the Arbitration Costs as the arbitrator deems necessary to
              prevent the arbitration from being cost-prohibitive.
            </Paragraph>
            <Paragraph>
              Choice of Forum: A court, not the arbitrator, will decide any questions regarding the validity, scope
              and/or enforceability of this Section 6.b. Any litigated action (as opposed to an arbitration) regarding,
              relating to or involving the validity, scope and/or enforceability of this Section 6.b or otherwise, shall
              be brought in either the courts of the State of Georgia sitting in Muscogee County or the United States
              District Court for the Northern District of Georgia, and you expressly agree to the exclusive jurisdiction
              of such courts. You hereby agree and consent to the personal jurisdiction and venue of such courts, and
              expressly waive any objection that you might otherwise have to personal jurisdiction or venue in such
              courts.
            </Paragraph>
          </Paragraph>
          <Paragraph>
            c. Class Action Waiver: YOU ACKNOWLEDGE AND AGREE THAT ALL DISPUTES ARISING OUT OF OR RELATED TO THESE TERMS
            SHALL BE RESOLVED ON AN INDIVIDUAL BASIS WITHOUT RESORT TO ANY FORM OF CLASS ACTION AND SHALL NOT BE
            CONSOLIDATED WITH THE CLAIMS OF ANY OTHER PARTIES. YOU FURTHER AGREE TO WAIVE, AND HEREBY WAIVE, THE RIGHT
            TO PARTICIPATE IN A CLASS ACTION OR TO LITIGATE OR ARBITRATE ON A CLASS-WIDE BASIS.
          </Paragraph>
          <Paragraph>
            d. You and the Merchant shall attempt to resolve any disputes arising out of these Terms and Conditions by
            mutual agreement.
          </Paragraph>

          <TermsSubTitle>7. DISCLAIMER OF WARRANTIES, WAIVER, AND LIMITATION OF LIABILITY</TermsSubTitle>

          <Paragraph>
            a. DISCLAIMER OF WARRANTIES
            <br />
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE LOYALTY PROGRAM AND ALL INFORMATION, PRODUCTS AND
            OTHER CONTENT (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR ACCESSIBLE FROM THE LOYALTY PROGRAM IS AT
            YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS. NEITHER
            GLOBAL PAYMENTS NOR ITS THIRD-PARTY PROVIDERS WILL BE LIABLE OR RESPONSIBLE FOR ANY PRODUCTS OR SERVICES
            PROVIDED BY MERCHANTS THAT ARE A CAUSE OF INJURY OR THAT ARE UNACCEPTABLE OR DO NOT MEET YOUR REQUIREMENTS
            OR EXPECTATIONS. EXCEPT FOR THE EXPRESS WARRANTIES SET FORTH HEREIN, GLOBAL PAYMENTS AND ITS THIRD-PARTY
            PROVIDERS HEREBY EXPRESSLY DISCLAIM ALL EXPRESS OR IMPLIED WARRANTIES WITH REGARD TO THE LOYALTY PROGRAM AND
            ALL INFORMATION, PRODUCTS AND OTHER CONTENT (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR ACCESSIBLE FROM
            THE LOYALTY PROGRAM, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE, NON- INFRINGEMENT AND QUALITY. GLOBAL PAYMENTS DOES NOT REPRESENT OR WARRANT THAT
            THE INFORMATION ACCESSIBLE THROUGH THE LOYALTY PROGRAM IS ACCURATE, COMPLETE, RELIABLE, CURRENT, OR
            ERROR-FREE.
          </Paragraph>

          <Paragraph>
            b. WAIVER.
            <br />
            GLOBAL PAYMENTS AND ITS THIRD-PARTY PROVIDERS MAKE NO REPRESENTATIONS OR WARRANTIES REGARDING (I) WHETHER
            THE LOYALTY PROGRAM WILL MEET YOUR REQUIREMENTS; (II) THE RELIABILITY, AVAILABILITY, TIMELINESS,
            SUITABILITY, ACCURACY OR COMPLETENESS OF THE LOYALTY PROGRAM; (III) THE RESULTS YOU MAY OBTAIN BY USING THE
            LOYALTY PROGRAM; (IV) WHETHER THE OPERATION OR USE OF THE LOYALTY PROGRAM WILL BE UNINTERRUPTED OR
            ERROR-FREE; OR (V) WHETHER THE QUALITY OF THE SERVICE, OR PRODUCTS OR SERVICE, INFORMATION OR OTHER MATERIAL
            PURCHASED OR OBTAINED THROUGH THE LOYALTY PROGRAM WILL MEET YOUR EXPECTATIONS.
          </Paragraph>
          <Paragraph>
            c. LIMITATION OF LIABILITY
            <br />
            IN NO EVENT WILL GLOBAL PAYMENTS BE LIABLE UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY,
            FOR ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE, INDIRECT, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES,
            INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOST PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, EVEN
            IF GLOBAL PAYMENTS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND EVEN IF A REMEDY SET FORTH HEREIN
            HAS FAILED ITS ESSENTIAL PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY LAW GLOBAL PAYMENTS’ AGGREGATE
            LIABILITY TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE IS LIMITED TO ONE HUNDRED DOLLARS ($100).
          </Paragraph>
          <Paragraph>
            d. State Exemptions
            <br />
            Some states do not allow exclusion of implied warranties or limitation of liability for incidental or
            consequential damages, so the above limitations or exclusions may not apply to you. IN SUCH STATES, GLOBAL
            PAYMENTS LIABILITY WILL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
          </Paragraph>
          <Paragraph>
            e. Reliance on Limitations. Each party acknowledges that the other party has entered into these Terms
            relying on the limitations of liability stated herein and that those limitations are an essential basis of
            the bargain between the parties.
          </Paragraph>

          <TermsSubTitle>8. INDEMNIFICATION</TermsSubTitle>
          <Paragraph>
            You agree to defend, indemnify and hold harmless Global Payments and its directors, officers, employees,
            affiliates and agents from and against any and all third party claims, liability, damages, expenses and
            costs actions or demands, including, without limitation, reasonable legal and accounting fees, arising or
            resulting from (a) your breach of these Terms or any other policy; (b) your access to, use, or misuse of the
            Third-Party Content or the Loyalty Program. Global Payments will provide notice to you of any such claim,
            suit, or proceeding. Global Payments reserves the right to assume the exclusive defense and control of any
            matter which is subject to indemnification under this Section. In such case, you agree to cooperate with any
            reasonable requests assisting Global Payments defense of such matter.
          </Paragraph>

          <TermsSubTitle>9. MISCELLANEOUS</TermsSubTitle>

          <Paragraph>
            a. Notice. All notices required to be given to Global Payments under these Terms shall be given and made in
            writing and shall be delivered to Global Payments Direct, Inc., 3550 Lenox Road, Suite 3000, Atlanta,
            Georgia 30326, ATTN: Corporate Secretary and deemed received (i) when received, if hand delivered or (ii)
            the day after it is sent, if delivered by an internationally-recognized common carrier&apos;s overnight
            delivery service providing proof of delivery.
          </Paragraph>
          <Paragraph>
            b. Assignment. You may not assign these Terms or any rights or obligations hereunder, by operation of law or
            otherwise, without our prior written consent and any attempted assignment may be void. We reserve the right
            to freely assign these Terms and the rights and obligations hereunder, to any third party without notice and
            consent. Subject to the foregoing, these Terms shall be binding upon and inure to the benefit of the parties
            hereto, their successors and permitted assigns.
          </Paragraph>
          <Paragraph>
            c. If any provision of these Terms is found to be invalid by any court having competent jurisdiction, the
            invalidity of such provision will not affect the validity of the remaining provisions of these Terms, which
            will remain in full force and effect. Failure of Global Payments to act on or enforce any provision of these
            Terms will not be construed as a waiver of that provision or any other provision herein. No waiver will be
            effective against Global Payments unless made in writing, and no such waiver will be construed as a waiver
            in any other or subsequent instance. Except as expressly agreed by Global Payments and you, these Terms,
            including any Global Payments policies governing the Loyalty Program referenced herein, constitute the
            entire agreement between you and Global Payments with respect to the subject matter hereof, and supersedes
            all previous or contemporaneous agreements, whether written or oral, between you and Global Payments with
            respect to the subject matter. The section headings are provided merely for convenience and will not be
            given any legal import.
          </Paragraph>
        </TermsPlainTextWrapper>
      </TermsTextWrapper>
    </PageWithLoader>
  );
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '../appStore';
import { getTermsAndConditionsData } from '../../api';

export const fetchData = createAsyncThunk<
  Pick<TermsAndConditionsState, 'merchantInfo'>,
  { account: string; jwt: string }
>('termsAndConditions/fetchText', async function (data: { account: string; jwt: string }, { rejectWithValue }) {
  const response = await getTermsAndConditionsData(data);
  if (response) {
    return response;
  } else {
    return rejectWithValue('Error fetching Terms and Conditions');
  }
});

export interface MerchantInfo {
  merchantName: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  zip: string;
  email: string;
  courtName: string;
  fileNumber: string;
  pointThreshhold: string;
  rewardAmount: string;
  activationDate: string;
  businessId: string;
  termsUrl: string;
  privacyPolicyUrl: string;
}

export interface TermsAndConditionsState {
  merchantInfo: MerchantInfo;
  text: string;
  status: string | null;
  error: string | null;
}

const initialState: TermsAndConditionsState = {
  merchantInfo: {
    merchantName: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    zip: '',
    email: '',
    courtName: '',
    fileNumber: '',
    pointThreshhold: '',
    rewardAmount: '',
    activationDate: '',
    businessId: '',
    termsUrl: '',
    privacyPolicyUrl: ''
  },
  text: '',
  status: null,
  error: null
};

export const termsAndConditionsSlice = createSlice({
  name: 'termsAndConditions',
  initialState,
  reducers: {},
  extraReducers: {
    // @ts-ignore
    [fetchData.pending]: state => {
      state.status = 'pending';
      state.error = null;
    },
    // @ts-ignore
    [fetchData.fulfilled]: (state, action) => {
      state.status = 'resolved';
      state.merchantInfo = action.payload;
    },
    // @ts-ignore
    [fetchData.rejected]: (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    }
  }
});

export const selectMerchantInfo = (state: RootState) => state.termsAndConditions.merchantInfo;
export const getStatus = (state: RootState) => state.termsAndConditions.status;
export const termsAndConditionsReducer = termsAndConditionsSlice.reducer;

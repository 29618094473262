import styled from 'styled-components';

const MOBILE_WIDTH = 768;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 1.3;
  text-align: justify;
`;

export const PageContainer = styled.div`
  background: #f8f9fa;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: start;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  max-width: 400px;
  color: #54575a;
  border: 1px solid #e0e0e0;

  @media (max-width: ${MOBILE_WIDTH}px) {
    border-left: 0;
    border-right: 0;
  }
`;

export const ConfirmationBox = styled(Box)`
  margin-top: 30px;
  padding: 50px 90px 40px;

  @media (max-width: ${MOBILE_WIDTH}px) {
    margin-top: 20px;
    padding: 30px 20px 20px;
  }
`;

export const ConfirmationTitle = styled(Title)`
  margin-bottom: 30px;
`;

export const ConfirmationText = styled(Text)`
  margin-bottom: 30px;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 30px;
`;

export const Checkbox = styled.input`
  margin: 2px 15px 0 0;
`;

export const CheckboxLabel = styled.label`
  font-size: 14px;
  text-align: justify;
`;

export const ResultBox = styled(Box)`
  margin-top: 30px;
  padding: 30px 90px 30px;

  @media (max-width: ${MOBILE_WIDTH}px) {
    margin-top: 20px;
    padding: 20px 20px 20px;
  }
`;

export const ResultTitle = styled(Title)`
  text-align: justify;
  margin-bottom: 10px;
`;

export const ResultText = styled(Text)``;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SubmitButton = styled.button`
  height: fit-content;
  width: fit-content;
  padding: 10px 22px;
  user-select: none;
  outline: none;
  cursor: pointer;
  position: relative;

  color: #ffffff;
  background-color: #0072ce;
  border: 1px solid #0072ce;
  text-shadow: 0px 0px 1px;

  &:disabled {
    cursor: not-allowed;
    color: #ffffff;
    background-color: #e0e0e0;
    border-color: #e0e0e0;
  }
`;

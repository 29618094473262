import styled from 'styled-components';

export const TermsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0 80px 0;
`;

export const TermsPlainTextWrapper = styled.div`
  text-align: left;
  margin: 0 10%;
`;

export const TermsSubTitle = styled.div`
  text-align: left;
  margin: 25px 0;
  font-weight: bold;
`;

export const TermsTitle = styled.div`
  text-align: center;
  margin: 25px 0;
`;

export const TermsPlainText = styled.div`
  line-height: 20px;
  margin-bottom: 12px;
`;

export const BorderedTableCell = styled.td`
  border: 1px solid black;
  padding: 10px;
  align-content: start;
`;

export const BorderedTableCellBold = styled(BorderedTableCell)`
  font-weight: bold;
`;

export const StyledTable = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
  margin: 30px 0;
`;

export const Footer = styled.div`
  line-height: 20px;
  margin-top: 50px;
`;

export const Paragraph = styled.div`
  line-height: 20px;
  margin-left: 50px;
  margin-top: 10px;
`;

export const SubParagraph = styled.div`
  line-height: 20px;
  margin-left: 100px;
  margin-top: 20px;
`;

import React, { useEffect } from 'react';
import { KATANA_WIDGET_DOMAIN, KATANA_DOMAIN, KATANA_API_KEY } from '../../../../../api/constants';
import useScript from 'hooks/scriptHook';
import useStylesLink from 'hooks/stylesLinkHook';

export const DanubeUnsubscribeEmailMarketing = () => {
  useStylesLink(`${KATANA_WIDGET_DOMAIN}/katana/v5/widgets/widget-unsubscribe/styles.css`);

  useEffect(() => {
    const root = document.createElement('app-root');
    document.body.appendChild(root);
  }, []);

  useScript(`${KATANA_WIDGET_DOMAIN}/katana/v5/widgets/widget-unsubscribe/polyfills.js`);
  useScript(
    `${KATANA_WIDGET_DOMAIN}/katana/v5/widgets/widget-unsubscribe/main.js?domain=${KATANA_DOMAIN}&katanaApiKey=${KATANA_API_KEY}`,
    true
  );

  return <></>;
};

import { useEffect } from 'react';

const useStylesLink = (url: string) => {
  useEffect(() => {
    const link = document.createElement('link');

    link.rel = 'stylesheet';
    link.href = url;

    console.log('USE EFFECT STYLES');
    console.log('link', link);
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, [url]);
};

export default useStylesLink;

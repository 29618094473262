import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, selectMerchantInfo } from '../../../../store/termsAndConditions/termsAndConditionsSlice';
import { CZPrivacyPolicy } from './CZPrivacyPolicy';
import { MerchantInfo } from '../../../../store/termsAndConditions/termsAndConditionsSlice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { PageWithLoader } from '../../../PageWithLoader';

const getPrivacyPolicyViaAccount = (merchantInfo: MerchantInfo, account: string) => {
  const privacyPolicyViaAccount = {
    'mygp.cz': <CZPrivacyPolicy {...merchantInfo} />,
    'mygp.ro': <CZPrivacyPolicy {...merchantInfo} />, // Implement RO component when text provided
    'mygp.at': <CZPrivacyPolicy {...merchantInfo} />, // Implement AT component when text provided
    'mygp.sk': <CZPrivacyPolicy {...merchantInfo} /> // Implement SK component when text provided
  };

  return privacyPolicyViaAccount[account];
};

export const DanubePrivacyPolicy = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const merchantInfo = useSelector(selectMerchantInfo);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [account, setAccount] = useState<string>('');
  useEffect(() => {
    const account = searchParams.get('account');
    const jwt = searchParams.get('jwt');

    if (account && jwt) {
      setAccount(account);
      dispatch(fetchData({ account, jwt }));
    }
  }, [dispatch, searchParams, setSearchParams]);

  return <PageWithLoader>{getPrivacyPolicyViaAccount(merchantInfo, account)}</PageWithLoader>;
};

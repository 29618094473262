import React, { useEffect } from 'react';
import moment from 'moment/moment';
import { MerchantInfo } from '../../../../store/termsAndConditions/termsAndConditionsSlice';
import { TermsTextWrapper, TermsPlainTextWrapper, TermsSubTitle, TermsPlainText, Footer } from '../../../styles';

export const CZPrivacyPolicy = (merchantInfo: MerchantInfo) => {
  const {
    merchantName,
    courtName,
    address1,
    address2,
    city,
    country,
    zip,
    fileNumber,
    email,
    activationDate,
    businessId
  } = merchantInfo;
  const companyAddress = [address1, address2, city, country, zip].filter(item => !!item).join(', ');
  const formattedActivationDate = moment(activationDate).format('DD.MM.YYYY');

  const title: string = 'PROHLÁŠENÍ O OCHRANĚ OSOBNÍCH ÚDAJŮ';

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <TermsTextWrapper>
      <TermsPlainText>
        <b>{title}</b>
      </TermsPlainText>
      <TermsPlainText>
        <b>VĚRNOSTNÍ PROGRAM</b>
      </TermsPlainText>
      <TermsPlainTextWrapper>
        <TermsPlainText>
          V souvislosti s vaší účastí v našem věrnostním programu na základě Podmínek účasti ve věrnostním program (
          <b>„Věrnostní program“</b>) zpracováváme vaše osobní údaje způsobem a v rozsahu dále uvedeném v tomto
          Prohlášení o ochraně osobních údajů.
        </TermsPlainText>
        <TermsPlainText>
          Cílem tohoto dokumentu je poskytnout vám zejména informace o rozsahu a povaze tohoto zpracování, rozsahu
          vašich práv a způsobu, jakým je můžete uplatnit.
        </TermsPlainText>
        <TermsPlainText>
          Shromažďování a zpracování vašich osobních údajů probíhá v souladu s platnými právními předpisy o ochraně
          osobních údajů, zejména s obecným nařízením o ochraně osobních údajů (GDPR).
        </TermsPlainText>

        <TermsSubTitle>1. Kdo je správcem vašich osobních údajů?</TermsSubTitle>

        <TermsPlainText>
          Správcem vašich osobních údajů je společnost {merchantName ?? '[merchantName]'}, se sídlem{' '}
          {companyAddress ?? '[companyAddress]'}, identifikační číslo {businessId ?? '[businessId]'}, zapsaná v
          obchodním rejstříku vedeném u {courtName ?? '[courtName]'} pod sp. zn. {fileNumber ?? '[fileNumber]'} (dále
          jen „<b>Společnost</b>“ nebo „<b>my</b>“).
        </TermsPlainText>
        <TermsPlainText>
          V případě jakýchkoli žádostí týkajících se zpracování vašich osobních údajů nebo v případě uplatnění vašich
          práv uvedených v bodě 5 níže (včetně práva vznést námitku vůči zpracování) nás prosím kontaktujte na adrese
          {` ${email}` ?? ' [email]'}.
        </TermsPlainText>

        <TermsSubTitle>
          2. Jaké osobní údaje o vás shromažďujeme? Za jakým účelem, na jakém právním základě a na jak dlouho je
          shromažďujeme?
        </TermsSubTitle>

        <TermsPlainText>
          <i>Věrnostní program</i>
        </TermsPlainText>

        <TermsPlainText>
          Za účelem poskytování výhod v rámci Věrnostního programu zpracováváme vaše osobní údaje v rozsahu e-mailová
          adresa, token platební karty (jedná se o šifrované alfanumerické vyjádření čísla karty a její expirace), výše
          transakce, stav věrnostních bodů a odměn, datum připsání a expirace. Právním základem pro toto zpracování je
          plnění smlouvy (Podmínky účasti ve věrnostním programu), jejíž jste smluvní stranou. Určité údaje můžeme být
          rovněž povinni uchovávat, abychom dostáli právním povinnostem, které se na nás vztahují.
        </TermsPlainText>
        <TermsPlainText>
          Uvedené osobní údaje zpracováváme po dobu vaší účasti ve Věrnostním programu, ledaže právní předpisy určují
          jinou dobu, po kterou mají být údaje uchovány.
        </TermsPlainText>
        <TermsPlainText>
          <i>Obchodní sdělení</i>
        </TermsPlainText>
        <TermsPlainText>
          Vaši e-mailovou adresu a informace o stavu věrnostních bodů ve Věrnostním programu budeme rovněž zpracovávat
          za účelem zasílání obchodních sdělení týkajících se Vaší účasti ve Věrnostním programu a podpory našich
          produktů a/nebo služeb. Právním základem je náš oprávněný zájem na podpoře naší obchodní činnosti a přímém
          marketingu ve spojení s příslušnými ustanoveními zákona č. 480/2004 Sb., o některých službách informační
          společnosti, v platném znění.
        </TermsPlainText>
        <TermsPlainText>
          Ze zasílání obchodních sdělení se můžete kdykoliv odhlásit prostřednictvím odkazu umístěném v zápatí e-mailu s
          obchodním sdělením, které vám bylo zasláno. Zasílání obchodních sdělení můžete rovněž odmítnout v průběhu
          registrace do Věrnostního programu prostřednictvím odkazu v potvrzovacím e-mailu, který je Vám zaslán po
          registraci provedené na platebního terminálu.
        </TermsPlainText>
        <TermsPlainText>
          Vaše osobní údaje budou pro účely zasílání obchodních sdělení uchovávány po dobu vaší účasti ve Věrnostním
          programu, ledaže se ze zasílání obchodních sdělení odhlásíte nebo jej odmítnete při registraci.
        </TermsPlainText>
        <TermsPlainText>
          <i>Právní nároky</i>
        </TermsPlainText>
        <TermsPlainText>
          Vaše výše uvedené osobní údaje, včetně případných stížností z vaší strany, můžeme dále zpracovávat pro obranu
          našich právních nároků a pro případ potenciálního soudního sporu v budoucnu.
        </TermsPlainText>
        <TermsPlainText>
          Právním základem takového zpracování je náš oprávněný zájem, jelikož pro nás může být nezbytné uchovat důkazy
          pro případný spor, který může v budoucnu nastat. Tyto osobní údaje budou zpracovávány po dobu nezbytnou k
          naplnění tohoto účelu.{' '}
        </TermsPlainText>

        <TermsSubTitle>3. S kým sdílíme vaše osobní údaje?</TermsSubTitle>

        <TermsPlainText>
          Vaše osobní údaje mohou být zpřístupněny spolehlivým třetím stranám, které nám poskytují administrativní nebo
          technickou podporu, poskytovatelům IT systémů a datových serverů. V případě takového zpřístupnění zajistíme,
          aby příjemci dodržovali dostatečná technická a organizační bezpečnostní opatření, a zpřístupnění se bude řídit
          smlouvou splňující příslušné požadavky GDPR.
        </TermsPlainText>
        <TermsPlainText>
          Mohou nastat případy, kdy jsme ze zákona nebo na základě příkazu orgánu veřejné moci povinni sdílet vaše
          osobní údaje se státními orgány nebo orgány činnými v trestním řízení. V některých výjimečných případech
          můžeme také potřebovat sdílet vaše osobní údaje s našimi externími poradci, na které se vztahuje povinnost
          mlčenlivosti.{' '}
        </TermsPlainText>
        <TermsPlainText>
          V rámci naší Společnosti má k vašim osobním údajům přístup pouze omezený počet našich zaměstnanců. Tito
          zaměstnanci jsou povinni zachovávat přísnou důvěrnost vašich osobních údajů a opatření přijatých na jejich
          ochranu. S osobními údaji jsou oprávněni nakládat pouze na základě našich pokynů.{' '}
        </TermsPlainText>

        <TermsSubTitle>4. Předáváme vaše osobní údaje mimo Evropský hospodářský prostor?</TermsSubTitle>

        <TermsPlainText>
          Ne, vaše údaje jsou uchovávány na datových serverech nacházejících se v Evropské unii a nedochází tak k
          žádnému předání osobních údajů mimo území Evropského hospodářského prostoru.
        </TermsPlainText>

        <TermsSubTitle>5. Jaká jsou vaše práva v souvislosti se zpracováním?</TermsSubTitle>

        <TermsPlainText>
          Za určitých okolností máte právo vznést námitku proti zpracování vašich osobních údajů z naší strany, zejména
          pokud jsou vaše osobní údaje zpracovávány na základě oprávněného zájmu; v takovém případě vaši námitku
          posoudíme a o výsledku vás budeme informovat.
        </TermsPlainText>
        <TermsPlainText>
          Mezi vaše další práva patří: právo na přístup k vašim osobním údajům, právo na opravu nebo výmaz vašich
          osobních údajů, právo na omezení zpracování, právo na přenositelnost údajů.
        </TermsPlainText>
        <TermsPlainText>
          Z těchto práv však existují výjimky. Přístup k vašim údajům může být například odmítnut, pokud by zpřístupnění
          informací odhalilo osobní údaje jiné osoby nebo pokud nám ve zpřístupnění takových informací brání zákon.
        </TermsPlainText>
        <TermsPlainText>
          Rovněž máte právo podat stížnost u Úřadu pro ochranu osobních údajů, Pplk. Sochora 27, 170 00 Praha 7, Česká
          republika, e-mailová adresa: posta@uoou.cz.
        </TermsPlainText>

        <TermsSubTitle>6. Co když dojde ke změnám ve zpracování? </TermsSubTitle>

        <TermsPlainText>
          Toto Prohlášení o ochraně osobních údajů můžeme čas od času změnit. O všech podstatných změnách vás budeme
          informovat e-mailem nebo jiným vhodným způsobem.
        </TermsPlainText>
        <Footer>Aktualizováno: {formattedActivationDate}</Footer>
      </TermsPlainTextWrapper>
    </TermsTextWrapper>
  );
};

import type { AxiosError } from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { crmProcClient } from 'api/crmProcClient';
import type { AlreadyUnsubscribedResponse, OptOutResponse } from './types';

function isAlreadyUnsubscribedResponse(res: OptOutResponse): res is AlreadyUnsubscribedResponse {
  return typeof res !== 'string' && !res.success && res.error.message === 'ALREADY_UNSUBSCRIBED';
}

export const optOut = createAsyncThunk<Pick<EmOptOutState, 'status'>, { trackingCode: string }>(
  'emailMarketing/optOut',
  async ({ trackingCode }) => {
    try {
      const res = (await crmProcClient.unsubscribeContact(trackingCode)).data;

      if (typeof res === 'string') return { status: 'unexpectedError' };

      return { status: 'success' };
    } catch (e: any) {
      const error: AxiosError<OptOutResponse> = e;
      if (!error.response) return { status: 'unexpectedError' };

      const response = error.response.data;
      if (isAlreadyUnsubscribedResponse(response)) return { status: 'alreadyUnsubscribedError' };

      return { status: 'invalidCodeError' };
    }
  }
);

export interface EmOptOutState {
  pending: boolean;
  status: 'initial' | 'success' | 'invalidCodeError' | 'alreadyUnsubscribedError' | 'unexpectedError';
}

const initialState = { pending: false, status: 'initial' } as EmOptOutState;

const emOptOutSlice = createSlice({
  name: 'optOut',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(optOut.pending, state => {
        state.pending = true;
        state.status = 'initial';
      })
      .addCase(optOut.fulfilled, (state, { payload }) => {
        state.pending = false;
        state.status = payload.status;
      })
      .addCase(optOut.rejected, (state, action) => {
        state.pending = false;
        state.status = 'unexpectedError';
      });
  }
});

export const emOptOutReducer = emOptOutSlice.reducer;

import React, { useEffect } from 'react';
import { TranslationNamespace } from 'i18n';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/appStore';
import { ResultBox, ResultText, ResultTitle } from '../styles';

function Component({ title, text }: { title: string; text: string }) {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <ResultBox>
      <ResultTitle>{title}</ResultTitle>
      <ResultText>{text}</ResultText>
    </ResultBox>
  );
}

export function Status() {
  const optOutStatus = useSelector((state: RootState) => state.optOut.emailMarketing.status);
  const { t } = useTranslation(TranslationNamespace.EmOptout);
  const translations = {
    success: {
      title: t('EmOptout:status.success.title', 'Success!'),
      text: t('EmOptout:status.success.text', 'You have successfully been unsubscribed from email marketing messages.')
    },
    alreadyUnsubscribedError: {
      title: t('EmOptout:status.alreadyUnsubscribedError.title', 'Oops!'),
      text: t(
        'EmOptout:status.alreadyUnsubscribedError.text',
        'Looks like you have already unsubscribed from email marketing messages.'
      )
    },
    unexpectedError: {
      title: t('EmOptout:status.unexpectedError.title', 'Unexpected error!'),
      text: t(
        'EmOptout:status.unexpectedError.text',
        `An unexpected error occured and your request couldn't be completed.`
      )
    }
  };

  switch (optOutStatus) {
    case 'alreadyUnsubscribedError':
      return (
        <Component
          title={translations.alreadyUnsubscribedError.title}
          text={translations.alreadyUnsubscribedError.text}
        />
      );
    case 'invalidCodeError':
    case 'unexpectedError':
      return <Component title={translations.unexpectedError.title} text={translations.unexpectedError.text} />;
    default:
      return <Component title={translations.success.title} text={translations.success.text} />;
  }
}

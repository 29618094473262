import { useEffect } from 'react';

const useScript = (url: string, withId: boolean = false) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    if (withId) {
      script.id = 'widget-script';
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url, withId]);
};

export default useScript;

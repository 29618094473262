import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { termsAndConditionsReducer } from './termsAndConditions/termsAndConditionsSlice';
import { emOptOutReducer } from './email-marketing/opt-out';

export const store = configureStore({
  reducer: {
    termsAndConditions: termsAndConditionsReducer,
    optOut: combineReducers({
      emailMarketing: emOptOutReducer
    })
  }
});

export type RootState = ReturnType<typeof store.getState>;

import React, { useEffect } from 'react';
import { MerchantInfo } from '../../../../store/termsAndConditions/termsAndConditionsSlice';
import { TermsTextWrapper, TermsPlainTextWrapper, TermsSubTitle, TermsPlainText, Footer } from '../../../styles';

export const PrivacyPolicy = (merchantInfo: MerchantInfo) => {
  const { merchantName } = merchantInfo;

  const title: string = 'Last Revised January 10, 2024';

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <TermsTextWrapper>
      <TermsPlainTextWrapper>
        <TermsPlainText>{title}</TermsPlainText>
        <TermsPlainText>
          PLEASE READ THIS PRIVACY POLICY AND THE RELATED TERMS OF SERVICE CAREFULLY BEFORE USING THIS SERVICE OR OPTING
          IN TO RECEIVING EMAIL MARKETING FROM COMPANY. BY USING THE SERVICE OR OPTING IN TO RECEIVING EMAIL MARKETING,
          YOU AGREE TO THIS PRIVACY POLICY INCLUDING WITHOUT LIMITATION, THE ARBITRATION AGREEMENT AND CLASS ACTION
          WAIVER DESCRIBED IN PARAGRAPH 17 BELOW.
        </TermsPlainText>

        <TermsPlainText>
          This “Service” (as defined herein) is offered by {merchantName ?? '[merchantName]'} in combination with one of
          its affiliates, parents or subsidiaries (collectively referred to herein, solely for ease of reference, as
          “Company”, “us” or “we”), and is provided by Global Payments, Inc. This privacy policy (“Privacy Policy”)
          applies to your decision to opt-into receiving email marketing materials from Company and the ongoing receipt
          of such materials (the “Service”) (unless a different policy is provided on a particular Service or
          application, in which case such different policy shall control). This Privacy Policy discloses what type of
          information is collected, the methods used to collect it, how Company utilizes the information to better serve
          all interested parties and the choices you have about the way your information is collected and used. Please
          read this Privacy Policy carefully. By clicking “Submit” on the dialog box that linked you to this Privacy
          Policy, you indicate that you have read, accepted and unconditionally agreed to the Company privacy practices,
          as outlined in this Privacy Policy and any other applicable terms. The Terms of Service for the Service are
          incorporated herein by this reference. For purposes of this Privacy Policy, personally identifiable
          information (“PII”) is personal information about an individual, such as a first and last name, phone number
          or email address or other individually identifiable information within the meaning of the Federal Trade
          Commission’s Online Privacy Protection Rule found at 16 CFR 312.2. Every user hereby agrees that all
          information you provide, including but not limited to age, shall be truthful and correct.
        </TermsPlainText>

        <TermsSubTitle>
          <u>1. Information We Collect</u>
        </TermsSubTitle>

        <TermsPlainText>
          Company strives to provide our subscribers with information relevant to their particular desires, needs and/or
          interests. The information we gather helps us to better serve our purpose. We may ask you to provide us with
          demographic information, information regarding your interests or other PII. Providing us with PII about
          yourself is voluntary, and you can always choose not to provide certain information, but then you may not be
          able to take advantage of or participate in some of the Service’s features. Company actively and passively
          gathers, analyzes and/or stores PII and other information through various sources, including without
          limitation: (1) the PII voluntarily submitted by subscribers when enrolling in and otherwise using the
          Service, (2) the PII subscribers voluntarily provide when participating in any contests, sweepstakes and/or
          other promotions; and (3) by tracking and analyzing online and mobile data and activity through mechanisms
          that identify Service usage, browser types, IP addresses, and other data. We do not collect PII through
          advertisements on the Service and the PII we collect is not shared with third parties except as set forth
          herein. We will make reasonable efforts to destroy, erase or make anonymous any PII that is no longer required
          to fulfill the original purpose for which it was originally collected.
        </TermsPlainText>

        <TermsSubTitle>
          <u>2. Usage Information</u>
        </TermsSubTitle>

        <TermsPlainText>
          When your computer or mobile device contacts our web servers (for example, when you use the Service, or view
          an HTML e-mail), our web servers automatically collect usage information. Such usage information includes
          information about how our visitors use the Service. It can include the number and frequency of visitors to
          each web page and the length of their stays, browser type, referrer data that identifies the web page visited
          prior and subsequent to visiting the Service, and IP addresses (see below for more information on IP
          addresses). We also may determine the applicable technology available in order to serve you the most
          appropriate version of a web page, e-mail, advertising or promotional announcement or similar service. This
          information is used to analyze and improve this Service and to provide you with a more fulfilling and relevant
          experience.
        </TermsPlainText>

        <TermsSubTitle>
          <u>3. Browser Cookies</u>
        </TermsSubTitle>
        <TermsPlainText>
          Like many websites, we may use browser “cookies.” Cookies are a website’s way of remembering who you are. A
          cookie is a small text file that is stored on your computer’s hard drive or stored temporarily in your
          computer’s memory. There are two kinds of cookies: those that are “session” oriented and those that are
          “persistent.” Session cookies delete from your computer when you close your browser. Persistent cookies retain
          information for later use tomorrow, next week, next month, or whenever they are set to expire. Also, we will
          use cookies to monitor and maintain information about your use of the Service. Most web browsers accept
          cookies automatically. You can delete cookies manually or set your browser to automatically delete cookies on
          a pre-determined schedule. For example, in the Internet Explorer menu bar, select: Tools → Internet Options →
          Browsing History → Delete to view manual and automatic options. If you decline to accept cookies, you may not
          be able to take advantage of or participate in certain features of the Service.
        </TermsPlainText>

        <TermsSubTitle>
          <u>4. Flash Cookies</u>
        </TermsSubTitle>
        <TermsPlainText>
          The Service may enable the use of the Adobe Flash Player. Adobe’s Flash Player is used by many websites that
          offer video and other interactive content. By default, your use of the Adobe Flash Player generates “flash
          cookies” (also known as “persistent identification elements” or “local shared objects”). Adobe provides a
          short disclosure about Flash Cookies in its End User License Agreement, stating “Use of the web players,
          specifically the Flash Player, will enable the Software to store certain user settings as a local shared
          object on our computer. These settings are not associated with you, but allow you to configure certain
          settings within the Flash Player.” The Adobe Flash Player (and similar applications) use flash cookies to
          remember user settings, preferences and usage similar to the browser cookies referenced above, but flash
          cookies can store more information than browser cookies and are managed through a different interface than the
          one provided by your web browser. You can control the degree to which you accept flash cookies by accessing
          your Adobe Flash Player management tools directly through the settings manager for Adobe Flash, located at
          http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html . If you do not
          allow flash cookies to take any space on your computer or device, you may not be able to take advantage of or
          participate in certain features on the Service. Users with a computer running the Windows operating system can
          view flash cookie files in this folder: \Documents and Settings\[username]\Application Data\Macromedia\Flash
          Player. Users with a computer running the Apple operating system can view flash cookie files in this folder:
          /users/[username]/Library/Preferences/macromedia/Flash Player. Flash cookies, or LSO files are stored
          typically with an “.SOL” extension. To the extent the Google Display Network does not allow flash cookies to
          be used for behavioral advertising, ad delivery, or multi-site advertising, the Service is designed to conform
          to that restriction.
        </TermsPlainText>
        <TermsPlainText>
          The Adobe Flash Player is not the only technology being used in the ever-shifting online and mobile content
          environment. HTML5 is a popular web standard used for presenting content, especially content delivered to
          mobile devices. HTML is the mark-up language used for the World Wide Web. Almost all web pages you visit on
          the Internet are based around HTML code. HTML5 is simply the fifth and latest iteration of this mark-up
          language that allows for more interactive web pages. One of the real benefits of HTML5 is its potential to
          standardize the highly fragmented rich-media universe. Some HTML5 code may allow your response to advertising
          and other activities to be monitored across websites and such information to be stored on your computer or
          mobile device. Technology solutions that allow users to opt-out of or block this sort of tracking are being
          developed through browser add-ons and other tools.
        </TermsPlainText>
        <TermsPlainText>
          To the extent the Google Display Network does not allow HTML5 local storage to be used for behavioral
          advertising, ad delivery, or multi-site advertising, the Service is designed to conform to that restriction.
        </TermsPlainText>

        <TermsSubTitle>
          <u>5. Web Beacons</u>
        </TermsSubTitle>
        <TermsPlainText>
          Our web pages or e-mail messages may contain a small graphic image called a web beacon, which is sometimes
          also called a “clear gif”, that allows us to monitor and collect certain limited information about our users,
          such as the type of browser requesting the web beacon, the IP address of the computer that the web beacon is
          sent to and the time the web beacon was viewed. Web beacons can be very small or invisible to the user, but,
          in general, any electronic image viewed as part of a web page or e-mail, including HTML based content, can act
          as a web beacon. We may use web beacons to count visitors to our web pages or to monitor how our users
          navigate the Service, and we may include web beacons in e-mail messages in order to count how many of the
          messages we sent were actually opened or acted upon. We use web beacons to compile aggregate statistics about
          the Service and our marketing campaigns.
        </TermsPlainText>

        <TermsSubTitle>
          <u>6. Use Of IP Addresses</u>
        </TermsSubTitle>
        <TermsPlainText>
          An IP address is a number that is assigned to your computer or network when you are linked to the Internet.
          When you request pages from the Service, our servers log your IP address. We may use IP address for a number
          of purposes, such as system administration, to report aggregate information to our business partners or to
          audit the use of the Service. We may associate your IP address with the PII you provide.
        </TermsPlainText>
        <TermsPlainText>
          We also may collect and store information about you that we receive from other sources to enable us to update
          and correct the information contained in our database and to provide product ecommendations and special offers
          that we think will interest you.
        </TermsPlainText>

        <TermsSubTitle>
          <u>7. Information Regarding Your Video Viewing Activity</u>
        </TermsSubTitle>
        <TermsPlainText>
          We may also collect information about how you interact with live-streaming, cached and other video content
          offered through the Service. This information could be shared as set forth in this Privacy Policy or made
          public in a variety of ways. To the extent a user must consent in a signed writing under any specific law to
          such information collection and sharing activity, you are hereby deemed to have so consented and your
          agreement to this Privacy Policy in general also constitutes your signed consent to the foregoing.
        </TermsPlainText>

        <TermsSubTitle>
          <u>8. Sensitive Information</u>
        </TermsSubTitle>
        <TermsPlainText>
          Company uses modern technological efforts to ensure the safety of your sensitive information. No web, internet
          transmission, computer system or wireless connection is completely secure. Company cannot guarantee that
          unauthorized access, hacking, data loss, or other breaches will never occur. Your use of the Service is at
          your own risk. Whenever you give Company sensitive or confidential information, Company will take commercially
          reasonable steps to protect the information by establishing a secure connection with your web browser. Company
          uses an industry standard security protocol for encrypting sensitive information. Unfortunately, no security
          measures are perfect or impenetrable and data transmission over the Internet cannot be guaranteed to be 100%
          secure. We cannot and do not ensure or warrant the security of any information you transmit to Company and you
          do so at your own risk.
        </TermsPlainText>

        <TermsSubTitle>
          <u>9. External Links</u>
        </TermsSubTitle>
        <TermsPlainText>
          The Service may contain links to, or advertisements about, non-Company websites or services. Company does not
          endorse or sponsor other websites or services, is not responsible for the privacy practices or the content of
          non-Company websites, expressly disclaims any statements or assertions made on such websites or services, and
          denies all liability associated with your use of, and the content on, such other websites, services and
          advertisements. Please be advised that the practices described in this Privacy Policy do not apply to
          information gathered through other websites or services. We encourage you to be aware of when you leave the
          Service and to read the privacy policies of each and every website and service that you visit.
        </TermsPlainText>

        <TermsSubTitle>
          <u>10. Consent to Processing</u>
        </TermsSubTitle>
        <TermsPlainText>
          By using the Service, participating in any of our services and/or providing us with your information, you
          consent and agree to the collection, transfer, storage and processing of your information to and in the United
          States.
        </TermsPlainText>

        <TermsSubTitle>
          <u>11. Sharing Information</u>
        </TermsSubTitle>
        <TermsPlainText>
          Company may share your PII with third parties under the following circumstances:
        </TermsPlainText>
        <TermsPlainText>
          <i>Third parties providing services on our behalf</i>: We may engage third parties to perform services in
          connection with the operation of our business. Examples of these services include: (a) content providers and
          technology plug-ins (including third party video and other media players); (b) product customization, (c)
          marketing and promotional material distribution, (d) website evaluation, (e) data collection, storage,
          management, analysis and, where applicable, cleansing, and (f) any other services designed to assist us in
          maximizing our business potential. These third parties, including but not limited to Global Payments, Inc.,
          may have access to the Service’s user information, including PII, to the extent it is needed to perform their
          duties and functions.
        </TermsPlainText>
        <TermsPlainText>
          <i>Company Security</i>: We may release information about our users, including PII, when legally required to
          do so, at the request of governmental authorities conducting an investigation or to verify or enforce
          compliance with the policies governing the Service and applicable laws. We may also disclose such user
          information whenever we believe disclosure is necessary to protect the rights, property or safety of Company,
          or any of our respective affiliates, business partners, customers or others.
        </TermsPlainText>
        <TermsPlainText>
          <i>Aggregate Information</i>: We may disclose non-identifying, aggregated user statistics to third parties for
          a variety of purposes, including describing our services (including the Service) to prospective partners and
          other third parties. Examples of such non-personal information include the number of users who used the
          Service during a specific time period or purchased a specific product through the Service.
        </TermsPlainText>
        <TermsPlainText>
          <i>Promotions</i>: If you choose to enter a contest, sweepstakes or promotion, your PII may be disclosed to
          third parties in connection with such promotion, including without limitation for purposes of posting your
          entry with attribution or otherwise as permitted in the official rules for the promotion in question,
          fulfilling your prize or including your name on a winners list. Also, by entering a promotion, we may require
          you to consent to the use of your PII or other information, such as name, voice or likeness, in advertising,
          promotional and marketing materials. In addition, we may offer certain promotional content, such as a
          sweepstakes, sponsored by or co-branded with a third party, who may obtain the PII that you submit.
        </TermsPlainText>
        <TermsPlainText>
          <i>Corporate change</i>: In addition, we reserve the right to disclose, share and/or otherwise transfer user
          information, including without limitation PII, in connection with a corporate merger, acquisition,
          consolidation, the sale of a portion of our business or the sale of substantially all of Company’s assets, or
          other fundamental corporate change, whatever form it may take. You will be notified via e-mail or prominent
          notice in an email message transmitted through the Service prior to a change of ownership or control of your
          personal information, if your personal information will be used contrary to this policy. For clarification and
          the removal of doubt, Company specifically reserves the right to transfer or share a copy of PII collected
          pursuant to this Privacy Policy from the Service, off-line or otherwise, to the buyer of that portion of its
          business relating to that information.
        </TermsPlainText>

        <TermsSubTitle>
          <u>12. How Can I Access, Correct and Update Personal Information?</u>
        </TermsSubTitle>
        <TermsPlainText>
          You can access, correct and update certain personal information that you have provided to us, if any, by
          emailing us using the link in the bottom of each e-mail message that we send through the Service or by
          visiting our website and emailing us at the address listed on the “Contact Us” page. Please be aware that we
          will process your request as quickly as we reasonably can, but it may not be possible to update our systems
          immediately.
        </TermsPlainText>
        <TermsPlainText>
          In addition, you should be aware that it is not always possible to completely remove or delete all of your
          information from our databases without some residual data because of backups and other reasons.
        </TermsPlainText>

        <TermsSubTitle>
          <u>13. Your choices/unsubscribe</u>
        </TermsSubTitle>
        <TermsPlainText>
          You will have an opportunity to unsubscribe from receiving promotional material from the Service by clicking
          on an “unsubscribe” hyperlink contained in promotional emails we send you. Additionally you may send us a
          message by visiting our website and emailing us using the “Contact Us” page. Because customer lists often are
          prepared well in advance of an offering (sometimes a few months before the offer is made), you may continue to
          receive some offers after you send us a request not to use your information for specified marketing purposes.
          Please be aware that we will process your request as quickly as we reasonably can, but it may not be possible
          to update our systems immediately. We appreciate your patience and understanding in giving us time to carry
          out your request.
        </TermsPlainText>
        <TermsPlainText>
          In addition, you should be aware that it is not always possible to completely remove or delete all of your
          information, including PII, from our databases without some residual data because of backups and other
          reasons.
        </TermsPlainText>

        <TermsSubTitle>
          <u>14. Notice to California Residents/Your California Privacy Rights</u>
        </TermsSubTitle>
        <TermsPlainText>
          California residents are entitled once a year to request and obtain certain information regarding our
          disclosure, if any, of personal information to third parties for their direct marketing purposes during the
          immediately prior calendar year (e.g., requests made in 2016 will receive information about 2015 sharing
          activities). As set forth in this Privacy Policy, we comply with this law by not sharing your personally
          identifiable information with third parties for their direct marketing purposes. To make such a request,
          opt-out at the time that you sign up for the Service or send us a message using the “Contact Us” page on our
          website. You must include the Service as the subject line, and your full name, e-mail address, and postal
          address in your message. Please see the “Your Choices/Unsubscribe” section of this Privacy Policy for
          additional information. Please note that under California law, businesses are only required to respond to a
          customer request once during any calendar year.
        </TermsPlainText>
        <TermsPlainText>
          We may allow third-party companies to serve ads and/or collect certain anonymous information when you use the
          Service. These companies may use non-personally identifiable information (e.g., click stream information,
          browser type, time and date, subject of advertisements clicked or scrolled over) during your use of the
          Service and visits to other websites you access through your use of the Service in order to provide
          advertisements about goods and services likely to be of interest to you. These companies typically use a
          cookie or third party web beacon to collect this information. The Service does not recognize browser “Do Not
          Track” signals but several of our service providers who utilize these cookies or beacons on the Service enable
          you to opt out of this behavioral advertising. To learn more about how to opt out of the behavioral
          advertising conducted by these providers you can visit http://www.networkadvertising.org/ .
        </TermsPlainText>

        <TermsSubTitle>
          <u>15. Children</u>
        </TermsSubTitle>
        <TermsPlainText>
          Our Services are not targeted to children under thirteen (13) years of age and we do not knowingly collect PII
          from children under 13. If we discover that we collected PII about a child under, we will take steps to
          promptly delete such PII from our systems.
        </TermsPlainText>

        <TermsSubTitle>
          <u>16. Revisions to Privacy Policy</u>
        </TermsSubTitle>
        <TermsPlainText>
          We reserve the right, in our sole discretion, to modify, update, add to, discontinue, remove or otherwise
          change any portion of this Privacy Policy, in whole or in part, at any time. When we amend this Privacy
          Policy, we will revise the “last updated” date located at the top of this Privacy Policy. For changes to this
          Privacy Policy that we consider to be material, we will send an email and place a notice on the Company
          website located at https://www.globalpayments.com by revising the link to read substantially as “Updated
          Privacy Policy” for a reasonable amount of time. If you provide information to us, access or use the Service
          in any way, or do not unsubscribe from the Service after this Privacy Policy has been changed, you will be
          deemed to have unconditionally consented and agreed to such changes.
        </TermsPlainText>

        <TermsSubTitle>
          <u>17. Choice of Law</u>
        </TermsSubTitle>
        <TermsPlainText>
          This Privacy Policy shall be governed and construed in accordance with the laws of the State of Alaska
          applicable to contracts entered into and fully performed in Alaska (without regard to its conflicts of law
          principles that would cause the application of any other jurisdiction&apos;s laws). With respect to any
          disputes or claims not subject to arbitration, you agree not to commence or prosecute any action in connection
          therewith other than in the state and federal courts of Alaska, and you hereby consent to, and waive all
          defenses of lack of personal jurisdiction and forum non conveniens with respect to venue and jurisdiction in
          the state and federal courts of Alaska.
        </TermsPlainText>

        <TermsSubTitle>
          <u>18. Arbitration</u>
        </TermsSubTitle>
        <TermsPlainText>
          By using the Service in any way, you unconditionally consent and agree that: (1) any claim, dispute, or
          controversy (whether in contract, tort, or otherwise) you may have against Company and/or its parent,
          subsidiaries, affiliates and each of their respective members, officers, directors and employees (all such
          individuals and entities collectively referred to herein as the “Company Entities”) arising out of, relating
          to, or connected in any way with the Service or the determination of the scope or applicability of this
          agreement to arbitrate, will be resolved exclusively by final and binding arbitration administered by JAMS and
          conducted before a sole arbitrator in accordance with the rules of JAMS; (2) this arbitration agreement is
          made pursuant to a transaction involving interstate commerce, and shall be governed by the Federal Arbitration
          Act (“FAA”), 9 U.S.C. §§ 1-16; (3) the arbitration shall be held in Atlanta, Georgia; (4) the arbitrator’s
          decision shall be controlled by the terms and conditions of this Privacy Policy and any of the other
          agreements referenced herein that the applicable user may have entered into in connection with the Service;
          (5) the arbitrator shall apply Georgia law consistent with the FAA and applicable statutes of limitations, and
          shall honor claims of privilege recognized at law; (6) there shall be no authority for any claims to be
          arbitrated on a class or representative basis, arbitration can decide only your and/or the applicable Company
          Entity’s individual claims; the arbitrator may not consolidate or join the claims of other persons or parties
          who may be similarly situated; (7) the arbitrator shall not have the power to award punitive damages against
          you or any Company Entity; (8) in the event that the administrative fees and deposits that must be paid to
          initiate arbitration against any Company Entity exceed $125 USD, and you are unable (or not required under the
          rules of JAMS) to pay any fees and deposits that exceed this amount, Company agrees to pay them and/or forward
          them on your behalf, subject to ultimate allocation by the arbitrator. In addition, if you are able to
          demonstrate that the costs of arbitration will be prohibitive as compared to the costs of litigation, Company
          will pay as much of your filing and hearing fees in connection with the arbitration as the arbitrator deems
          necessary to prevent the arbitration from being cost-prohibitive; and (9) with the exception of subpart (6)
          above, if any part of this arbitration provision is deemed to be invalid, unenforceable or illegal, or
          otherwise conflicts with the rules of JAMS, then the balance of this arbitration provision shall remain in
          effect and shall be construed in accordance with its terms as if the invalid, unenforceable, illegal or
          conflicting provision were not contained herein. If, however, subpart (6) is found to be invalid,
          unenforceable or illegal, then the entirety of this Arbitration Provision shall be null and void, and neither
          you nor Company shall be entitled to arbitrate their dispute. For more information on JAMS and/or the rules of
          JAMS, visit their website at www.jamsadr.com .
        </TermsPlainText>

        <Footer>© 2024 Global Payments, Inc. All rights reserved.</Footer>
      </TermsPlainTextWrapper>
    </TermsTextWrapper>
  );
};

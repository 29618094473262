import React, { useEffect } from 'react';
import moment from 'moment';
import { MerchantInfo } from '../../../../store/termsAndConditions/termsAndConditionsSlice';
import { TermsTextWrapper, TermsPlainTextWrapper, TermsSubTitle, TermsPlainText, Footer } from '../../../styles';

export const CZTerms = (merchantInfo: MerchantInfo) => {
  const {
    merchantName,
    courtName,
    address1,
    address2,
    city,
    country,
    zip,
    fileNumber,
    email,
    pointThreshhold,
    rewardAmount,
    activationDate,
    businessId
  } = merchantInfo;
  const companyAddress = [address1, address2, city, country, zip].filter(item => !!item).join(', ');
  const formattedActivationDate = moment(activationDate).format('DD.MM.YYYY');

  const title: string = 'PODMÍNKY ÚČASTI VE VĚRNOSTNÍM PROGRAMU';

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <TermsTextWrapper>
      <h2>{title}</h2>
      <TermsPlainTextWrapper>
        <TermsSubTitle>1. ÚČAST VE VĚRNOSTNÍM PROGRAMU</TermsSubTitle>
        <TermsPlainText>
          1.1. Společnost {merchantName ?? '[merchantName]'}, se sídlem {companyAddress ?? '[companyAddress]'},
          identifikační číslo {businessId ?? '[businessId]'}, zapsaná v obchodním rejstříku vedeném u{' '}
          {courtName ?? '[courtName]'} pod sp. zn. {fileNumber ?? '[fileNumber]'}, e-mail {email ?? '[email]'} (
          <b>„Obchodník“</b>) provozuje věrnostní program („Věrnostní program“). Věrnostní program zákazníkům Obchodníka
          umožňuje sbírat věrnostní body při platbě kartou a následně uplatnit odměnu odpovídající nasbíraným věrnostním
          bodům způsobem popsaným v těchto podmínkách. Účast ve Věrnostním programu se řídí těmito podmínkami (
          <b>„Podmínky“</b>).
        </TermsPlainText>
        <TermsPlainText>
          1.2. Účastníkem Věrnostního programu se může stát jakákoli fyzická osoba, která dosáhla věku 18 let. Přijetím
          těchto Podmínek potvrzuje osoba, která chce vstoupit do Věrnostního programu, že dosáhla věku 18 let.
          Upozorňujeme, že Podmínky a ostatní dokumenty včetně související komunikace jsou výhradně v českém jazyce.
        </TermsPlainText>
        <TermsPlainText>
          1.3. Obchodník má tyto Podmínky a informace o Věrnostním programu, včetně Prohlášení o ochraně osobních údajů,
          zveřejněny ve svých obchodních prostorech a poskytne je zákazníkovi na základě jeho žádosti k nahlédnutí.
        </TermsPlainText>
        <TermsPlainText>
          1.4. Pro účast ve Věrnostním programu musí zákazník zaplatit u Obchodníka podporovanou platební kartou
          karetních asociací VISA nebo Mastercard vydanou v České republice k účtu pro nepodnikající fyzickou osobu.
          Pokud karta není přiřazena k žádnému zákaznickému účtu v rámci Věrnostního programu, vyzve platební terminál
          zákazníka k přistoupení k Věrnostnímu programu.
        </TermsPlainText>
        <TermsPlainText>
          1.5. Během výzvy k přistoupení k Věrnostnímu programu jsou zákazníkovi prostřednictvím QR kódu zpřístupněny
          tyto Podmínky, se kterými musí zákazník pro účast ve Věrnostním programu souhlasit, a Prohlášení o ochraně
          osobních údajů, u kterého musí zákazník potvrdit, že se s ním seznámil. Oba dokumenty jsou podle článku 1.3 k
          nahlédnutí u Obchodníka i v tištěné podobě.
        </TermsPlainText>
        <TermsPlainText>
          1.6. Zákazník je dále vyzván k zadání své e-mailové adresy, která bude využívána jako výlučný prostředek
          komunikace mezi Obchodníkem a zákazníkem, bude sloužit k identifikaci zákazníka a bude na ni navázán účet
          zákazníka v rámci Věrnostního programu. Zákazník není oprávněn zadat e-mailovou adresu jiné osoby.
        </TermsPlainText>
        <TermsPlainText>
          1.7. Zákazník vstupuje do Věrnostního programu okamžikem potvrzení „Zapojte se do věrnostního programu a
          získejte výhody!“ prostřednictvím platebního terminálu a od tohoto okamžiku mu jsou v rámci Věrnostního
          programu načítány věrnostní body dle pravidel uvedených v článku 2.
        </TermsPlainText>
        <TermsPlainText>
          1.8. Zákazníkovi je po vstupu do Věrnostního programu zaslán potvrzovací e-mail, ve kterém budou znovu
          zpřístupněny prostřednictvím statického odkazu tyto Podmínky a Prohlášení o ochraně osobních údajů. V
          potvrzovacím e-mailu je zákazník vyzván, aby svoji účast ve Věrnostním programu potvrdil do třiceti (30) dnů
          prostřednictvím odkazu v e-mailu k ověření zákazníkovy e-mailové adresy a dokončil tak aktivaci svého účtu v
          rámci Věrnostního programu. Ověřením e-mailové adresy zákazníka se stává účet zákazníka v rámci Věrnostního
          programu aktivní a zákazník je oprávněn nasbírané věrnostní body uplatnit dle pravidel uvedených v článku 3.
        </TermsPlainText>
        <TermsPlainText>
          1.9. Pokud není z jakéhokoli důvodu e-mailová adresa zákazníka ve Věrnostním programu potvrzena
          prostřednictvím e-mailu zaslaného na e-mailovou adresu zákazníka, zákazník není oprávněn věrnostní body
          využívat. Pokud zákazník svoji účast ve Věrnostním programu nepotvrdí ve lhůtě podle článku 1.8, bude jeho
          e-mailová adresa z Věrnostního programu automaticky odstraněna a jeho účast ve Věrnostním programu ukončena.
          Již nasbírané věrnostní body budou smazány. Při nové platbě u Obchodníka prostřednictvím platebního terminálu
          může být zákazník znovu vyzván k účasti ve Věrnostním programu.
        </TermsPlainText>
        <TermsPlainText>
          1.10. K jednomu účtu zákazníka je možné stejným postupem uvedeným výše (tj. zadáním stejné e-mailové adresy
          zákazníka při platbě jinou kartou) přiřadit více platebních karet.
        </TermsPlainText>
        <TermsPlainText>
          1.11. Účast ve Věrnostním programu je bezplatná a nezakládá povinnost nákupu zboží ani služeb od Obchodníka.
        </TermsPlainText>

        <TermsSubTitle>2. SBÍRÁNÍ A STAV VĚRNOSTNÍCH BODŮ</TermsSubTitle>

        <TermsPlainText>
          2.1. Při každé platbě podporovanou platební kartou karetních asociací VISA nebo Mastercard vydanou v České
          republice k účtu pro nepodnikající fyzickou osobu a registrovanou v rámci Věrnostního programu na platebním
          terminálu Obchodníka získá zákazník jeden (1) věrnostní bod za každou jednu (1) Kč provedeného nákupu.
          Informace o množství získaných věrnostních bodů bude při každé platbě zobrazena na platebním terminálů.
        </TermsPlainText>

        <TermsSubTitle>3. UPLATNĚNÍ VĚRNOSTNÍCH BODŮ</TermsSubTitle>

        <TermsPlainText>
          3.1. Při dosažení hranice {pointThreshhold ?? '[pointThreshhold]'} věrnostních bodů jsou věrnostní body v
          uvedeném množství automaticky převedeny na odměnu ve výši{' '}
          {rewardAmount ? `${parseInt(rewardAmount) / 100} Kč` : '[rewardAmount Kč]'}. Od tohoto okamžiku může zákazník
          po dobu třiceti (30) dní požádat o vyplacení odměny na podporovanou platební kartu karetních asociací VISA
          nebo Mastercard vydanou v České republice a registrovanou v rámci Věrnostního programu.
        </TermsPlainText>
        <TermsPlainText>
          3.2. Pokud dojde k dosažení uvedené hranice věrnostních bodů, bude zákazníkovi při platbě podporovanou
          platební kartou karetních asociací VISA nebo Mastercard vydanou v České republice a registrovanou v rámci
          Věrnostního programu na platebním terminálu Obchodníka zobrazena informace, že má nárok na vyplacení odměny.
          Vyplacení odměny lze potvrdit přes platební terminál a následně proběhne automaticky do čtyřiceti osmi (48)
          hodin od potvrzení; Obchodník si však vyhrazuje tuto lhůtu prodloužit, pokud by nastaly nepředvídatelné
          okolnosti (včetně technických poruch), které by vyplacení odměny v uvedené lhůtě znemožnily. Vyplacení odměny
          je realizováno prostřednictvím služeb Mastercard MoneySend nebo Visa Direct. Banka, která vydala podporovanou
          platební kartu karetních asociací VISA nebo Mastercard, na kterou má být odměna vyplacena, si může za tento
          typ transakce účtovat poplatek dle podmínek dané banky.
        </TermsPlainText>
        <TermsPlainText>
          3.3. Výše nasbíraných věrnostních bodů za platby platební kartou zákazníka registrovanou v rámci Věrnostního
          programu se nesnižuje v případě, že ze strany Obchodníka dojde k vrácení peněžních prostředků na registrovanou
          platební kartu zákazníka (z důvodu realizace transakce „Návrat“ a „Zrušení“ ze strany Obchodníka). Nasbírané
          věrnostní body jsou postupně mazány po tři sta šedesáti pěti (365) dnech ode dne, kdy byly v rámci Věrnostního
          programu připsány (ledaže byly dříve převedeny na odměnu). Odměna je v souladu s článkem 3.1 těchto Podmínek
          smazána vždy po třiceti (30) dnech ode dne, kdy mohla být poprvé uplatněna.
        </TermsPlainText>

        <TermsSubTitle>4. UKONČENÍ ÚČASTI VE VĚRNOSTNÍM PROGRAMU</TermsSubTitle>

        <TermsPlainText>
          4.1. Pokud zákazník s účastí ve Věrnostním programu nesouhlasí, lze se z Věrnostního programu odhlásit
          prostřednictvím odkazu „Odhlásit se z věrnostního programu“, který je obsažen v potvrzovacím e-mailu dle
          článku 1.8.
        </TermsPlainText>
        <TermsPlainText>
          4.2. V případě, že si zákazník bude přát ukončit svoji účast ve Věrnostním programu kdykoli po potvrzení své
          e-mailové adresy a aktivaci svého účtu ve Věrnostním programu, může tak učinit prostřednictvím odkazu
          „Odhlásit se z věrnostního programu“ v zápatí jakéhokoli e-mailu, který od Obchodníka obdrží. Po kliknutí na
          uvedený odkaz je zákazník přesměrován na internetovou stránku, kde potvrdí odhlášení z Věrnostního programu,
          čímž bude účast zákazníka ve Věrnostním programu ukončena. V případě ukončení účasti ve Věrnostním programu
          zákazník ztrácí všechny věrnostní body a výhody spojené s účastí ve Věrnostním programu, a údaje zákazníka
          budou bezodkladně odstraněny ze systému.
        </TermsPlainText>
        <TermsPlainText>
          4.3. Zákazník může také kdykoli ukončit svou účast ve Věrnostním programu bez výpovědní doby, a to oznámením
          zaslaným Obchodníkovi v písemné podobě na výše uvedené kontaktní údaje (poštou nebo e-mailem). V tomto
          oznámení uvede zákazník svou e-mailovou adresu, na kterou je jeho účet ve Věrnostním programu vázán. Účinky
          ukončení účasti ve Věrnostním programu nastávají doručením oznámení Obchodníkovi. Obchodník zašle zákazníkovi
          e-mail, v němž ukončení účasti zákazníka ve Věrnostním programu potvrdí.
        </TermsPlainText>
        <TermsPlainText>
          4.4. V případě nové registrace k Věrnostnímu programu neplynou z předchozí účasti zákazníka žádné výhody.
        </TermsPlainText>
        <TermsPlainText>
          4.5. Obchodník je oprávněn účet zákazníka ukončit v případě porušení těchto Podmínek uplynutím výpovědní doby
          v délce šedesáti (60) dní.
        </TermsPlainText>
        <TermsPlainText>
          4.6. Další důvody ukončení Věrnostního programu jsou uvedeny v článcích 5.5 a 5.6 těchto Podmínek.
        </TermsPlainText>

        <TermsSubTitle>5. OSTATNÍ</TermsSubTitle>

        <TermsPlainText>5.1. Tyto Podmínky se řídí českým právem.</TermsPlainText>
        <TermsPlainText>
          5.2. Jakékoli spory vzniklé z těchto Podmínek se Obchodník se zákazníkem pokusí vyřešit vzájemnou dohodou.
        </TermsPlainText>
        <TermsPlainText>
          5.3. V případě, že dojde mezi Obchodníkem a zákazníkem, který je spotřebitelem, ke vzniku spotřebitelského
          sporu z kupní smlouvy nebo ze smlouvy o poskytování služeb, který se nepodaří vyřešit vzájemnou dohodou, může
          zákazník využít platformy pro řešení sporů on-line (Online Dispute Resolution, ODR), přístupné na adrese{' '}
          <a href="http://ec.europa.eu/odr">http://ec.europa.eu/odr</a>, nebo může zákazník jako spotřebitel podat návrh
          na mimosoudní řešení takového sporu určenému subjektu mimosoudního řešení spotřebitelských sporů, kterým je:
        </TermsPlainText>
        <TermsPlainText>
          <b>Česká obchodní inspekce</b>
        </TermsPlainText>
        <TermsPlainText>Ústřední inspektorát – oddělení ADR</TermsPlainText>
        <TermsPlainText>Štěpánská 44</TermsPlainText>
        <TermsPlainText>110 00 Praha 1</TermsPlainText>
        <TermsPlainText></TermsPlainText>
        <TermsPlainText>Email: adr@coi.cz</TermsPlainText>
        <TermsPlainText>Web: adr.coi.cz</TermsPlainText>
        <TermsPlainText>
          5.4. Společnost Global Payments s.r.o., se sídlem V olšinách 626/80, Strašnice, PSČ 100 00, Praha 10, IČO: 042
          35 452, zapsaná v obchodním rejstříku vedeném u Městského soudu v Praze pod sp. zn. C 244453 (
          <b>„Global Payments“</b>) zajišťuje technické řešení prostřednictvím něhož Obchodník provozuje Věrnostní
          program.
        </TermsPlainText>
        <TermsPlainText>
          5.5. Obchodník si vyhrazuje právo ukončit, upravit či změnit Věrnostní program na základě oznámení učiněného
          třicet (30) dní předem, a v případě změny právních předpisů (včetně případů, kdy se tyto změny týkají činnosti
          Global Payments podle článku 5.4) i bez dodržení této lhůty.
        </TermsPlainText>
        <TermsPlainText>
          5.6. Pokud dojde se strany Global Payments bez předchozího oznámení k ukončení poskytování technického řešení,
          prostřednictvím něhož Obchodník provozuje Věrnostní program, a to z důvodů, které nemůže Obchodník při
          vyvinutí přiměřeného úsilí ovlivnit, nebo z důvodu závažného porušení smluvních podmínek sjednaných mezi
          Global Payments a Obchodníkem, je Obchodník oprávněn ukončit Věrnostní program s okamžitou účinností. V
          takovém případě zákazník ztrácí všechny věrnostní body a výhody spojené s účastí ve Věrnostním programu.
        </TermsPlainText>
        <TermsPlainText>
          5.7. Obchodník si dále vyhrazuje právo změnit či doplnit tyto Podmínky, zejména pokud je to v zájmu
          jednoduchosti a k zajištění bezpečného zpracování osobních údajů a věrnostních bodů a/nebo k předcházení
          zneužití systému. Změna Podmínek bude zákazníkovi oznámena předem prostřednictvím e-mailu. V případě
          nesouhlasu se změnou má zákazník právo svou účast ve Věrnostním programu ukončit s okamžitým účinkem před
          datem, kdy nastanou účinky příslušných změn.
        </TermsPlainText>
        <Footer>Aktualizováno: {formattedActivationDate}</Footer>
      </TermsPlainTextWrapper>
    </TermsTextWrapper>
  );
};

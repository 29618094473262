import React, { useEffect } from 'react';
import { fetchData, selectMerchantInfo } from '../../../../store/termsAndConditions/termsAndConditionsSlice';
import {
  TermsTextWrapper,
  TermsPlainTextWrapper,
  TermsSubTitle,
  TermsPlainText,
  BorderedTableCell,
  StyledTable,
  BorderedTableCellBold
} from '../../../styles';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { PageWithLoader } from '../../../PageWithLoader';

export const BroomfieldLoyaltyPrivacy = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { merchantName, termsUrl } = useSelector(selectMerchantInfo);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  useEffect(() => {
    const account = searchParams.get('account');
    const jwt = searchParams.get('jwt');

    if (account && jwt) {
      dispatch(fetchData({ account, jwt }));
    }
  }, [dispatch, searchParams, setSearchParams]);

  const title: string = 'Global Payments POS';

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <PageWithLoader>
      <TermsTextWrapper>
        <TermsPlainTextWrapper>
          <TermsSubTitle style={{ fontSize: 20, fontWeight: 'bold' }}>{title}</TermsSubTitle>
          <TermsSubTitle style={{ fontSize: 18, fontWeight: 'bold' }}>
            Loyalty and Email Marketing Privacy Notice
          </TermsSubTitle>
          <TermsSubTitle style={{ fontWeight: 'bold' }}>Last Updated: January 16, 2023</TermsSubTitle>
          <TermsPlainText>
            At Global Payments, we respect your concerns about privacy. This Privacy Notice (“Notice”) describes how
            Global Payments Inc. and its subsidiaries and affiliates (collectively referred to herein, solely for ease
            of reference, as “Global Payments”, “us”, or “we”) collect, use, and manage personal information that we
            collect as part of providing the Services (defined below). Please read this Notice carefully. If you have
            any questions, you may contact us at privacy@globalpay.com or by the methods provided in the “Contact Us”
            section below.
          </TermsPlainText>
          <TermsPlainText>
            Global Payments products and services include point-of-sale (POS) solutions that enable our business
            customers (each a “Merchant”, and collectively, “Merchants”) to process credit and debit payments and
            perform a variety of back-office functions. As part of those payment processing solutions, our POS system
            includes services designed to support Merchants in offering the following to their customers (“Customers”):
            (1) loyalty and other benefits programs (a “Loyalty Program”) and email marketing services (an “Email
            Marketing Campaign”). Loyalty Programs and Email Marketing Campaigns may be collectively referred to as the
            “Services” throughout the Notice, as context requires.{' '}
          </TermsPlainText>
          <TermsPlainText>
            As a Customer of {merchantName ?? '[organisation name]'}, one of our Merchant customers, this Notice applies
            to your use of one or more of the Services through the Global Payments POS system. This Notice relates only
            to personal information processed through the Services and it is provided as a supplement to any other
            applicable privacy notices provided to Merchants and/or Customers.
          </TermsPlainText>
          <TermsPlainText>
            Because our relationship with Customers is indirect, we process such Customer information only for the
            purposes of providing the Services, in accordance with our Merchants’ instructions. Merchants are
            responsible for establishing the <a href={termsUrl}>terms of their Loyalty Program</a> and the parameters of
            their Email Marketing Campaigns. Our Merchants also maintain their own business practices and policies with
            respect to data protection and have their own privacy notices. If you have a question about how personal
            information about you is collected, used, or disclosed by a Merchant, or would like to exercise any rights
            you may have with respect to such personal information, please contact the Merchant directly.
          </TermsPlainText>
          <TermsPlainText>
            By using the Services and/or providing us with personal information in connection with the Services, you
            acknowledge that personal information about you will be processed and used in the manner set out in this
            Notice.
          </TermsPlainText>
          <TermsPlainText>This Notice covers the following topics:</TermsPlainText>

          <ul>
            <li>
              <a href={'#personalInfo'}>Personal Information We Collect</a>
            </li>
            <li>
              <a href={'#sourcesOfPI'}>Sources of Personal Information</a>
            </li>
            <li>
              <a href={'#howUsePI'}>How We Use the Personal Information We Collect</a>
            </li>
            <li>
              <a href={'#disclosePI'}>How We Disclose the Personal Information We Collect</a>
            </li>
            <li>
              <a href={'#affiliateCompanies'}>Our Relationship with Affiliate Companies</a>
            </li>
            <li>
              <a href={'#financial'}>Notice of Financial Incentive / Bona Fide Loyalty Program</a>
            </li>
            <li>
              <a href={'#managingPreferences'}>Managing Your Preferences</a>
            </li>
            <li>
              <a href={'#legalRights'}>Your Legal Rights</a>
            </li>
            <li>
              <a href={'#dataSecurity'}>Data Security and Data Retention</a>
            </li>
            <li>
              <a href={'#cookies'}>Cookies and Other Tracking Technologies</a>
            </li>
            <li>
              <a href={'#externalLinks'}>External Links</a>
            </li>
            <li>
              <a href={'#children'}>Children</a>
            </li>
            <li>
              <a href={'#changesAndUpdates'}>Changes and Updates</a>
            </li>
            <li>
              <a href={'#contactUs'}>Contact Us</a>
            </li>
            <li>
              <a href={'#RegionSpecificInformation'}>Region-Specific Information</a>
            </li>
          </ul>

          <TermsSubTitle>
            <a id="personalInfo" href="/#">
              Personal Information We Collect
            </a>
          </TermsSubTitle>
          <TermsPlainText>
            “Personal information” is information that identifies you as an individual or relates to an identifiable
            individual. Subject to your consent if required by law, we may collect personal information from Customers
            in connection with the Services, on behalf of and as directed by our Merchants.
          </TermsPlainText>

          <TermsPlainText>
            If you choose to enroll in a Loyalty Program, we may collect the following types of personal information
            about you on behalf of our Merchants:
          </TermsPlainText>

          <ul>
            <li>
              <b>Basic Identifying Information</b>, including your full name, telephone number, and e-mail address.
            </li>
            <li>
              <b>Payment Information</b>, including credit or debit card numbers.
            </li>
            <li>
              <b>Commercial Information</b>, including records of your transactions with the Merchant(s).
            </li>
            <li>
              <b>Information You Provide</b>, including the contents of your communications with us and any associated
              metadata.
            </li>
            <li>
              <b>Inferences</b> drawn from any of the foregoing, such as inferences about your product preferences, your
              characteristics, or your predispositions.
            </li>
          </ul>

          <TermsPlainText>
            If you choose to subscribe to an Email Marketing Campaign, we may collect the following types of personal
            information about you on behalf of our Merchants:
          </TermsPlainText>

          <ul>
            <li>
              <b>Basic Identifying Information</b>, including your full name, telephone number, and e-mail address.
            </li>
            <li>
              <b>Commercial Information</b>, including records of your transactions with the Merchant(s) and information
              about your preferences regarding our Merchants’ products and services that may be of interest to you.
            </li>
            <li>
              <b>Device Information and Other Unique Identifiers</b>, including device identifier, internet protocol
              (IP) address, cookies, pixel tags, mobile ad identifier, or similar unique identifiers.
            </li>
            <li>
              <b>Internet or Other Network Activity</b>, including browsing or search history and information regarding
              your interactions with an Email Marketing Campaign.
            </li>
            <li>
              <b>Information You Provide</b>, including the contents of your communications with us and any associated
              metadata.
            </li>
            <li>
              <b>Inferences</b> drawn from any of the foregoing, such as inferences about your product preferences, your
              characteristics, or your predispositions.
            </li>
          </ul>

          <TermsPlainText>
            <u>Data Anonymization and Aggregation</u>. Subject to your consent if required by law, we may anonymize or
            aggregate personal information about you so that you are not identified or identifiable from it, in order to
            use the anonymized or aggregated data, for example, for statistical analysis and administration including
            analysis of trends or usage, to carry out actuarial work, to tailor products and services, and to conduct a
            risk assessment or analysis of costs and charges in relation to our Services. We may disclose anonymized or
            aggregated data to our global affiliates and with other third parties. This Notice does not restrict Global
            Payments&apos; use or disclosure of any anonymized or aggregated information.{' '}
          </TermsPlainText>
          <TermsPlainText>
            Where we maintain or use de-identified information, we will continue to maintain and use the de-identified
            information only in a de-identified fashion and will not attempt to re-identify the information.
          </TermsPlainText>

          <TermsSubTitle>
            <a id="sourcesOfPI" href="/#">
              Sources of Personal Information
            </a>
          </TermsSubTitle>
          <TermsPlainText>
            <b>Information that you provide to us</b>: We collect personal information that you provide to us when you
            enroll in the Service(s) or communicate with us. For example, if you register for one of the Services, we
            may request your name, phone number, and/or e-mail address. Providing us with personal information is
            voluntary, and you can always choose not to provide certain information, but then you may not be able to
            take advantage of or participate in some of the Services.
          </TermsPlainText>
          <TermsPlainText>
            <b>Information collected from third parties</b>: We may collect information about you from third parties in
            the course of providing the Services. For example, we may collect Basic Identifying Information and
            Commercial Information from Merchants in order to provide the Services.
          </TermsPlainText>
          <TermsPlainText>
            <b>Information collected through technology</b>: When you interact with an email we send to you as part of
            an Email Marketing Campaign, we may collect certain information automatically such as your account or device
            identifier and usage information such as information about links you click, the types of content you
            interact with, the frequency and duration of your activities, and other information about how you use the
            Services. You have the ability to express your preference regarding some of the ways we collect information
            through technology in some of the Services (see “Cookies and Other Tracking Technologies” for more
            information).
          </TermsPlainText>

          <TermsSubTitle>
            <a id="howUsePI" href="/#">
              How We Use the Personal Information We Collect
            </a>
          </TermsSubTitle>
          <TermsPlainText>
            Subject to your consent if required by law, we may use personal information about you for the following
            business and commercial purposes:
          </TermsPlainText>
          <ul>
            <li>To provide the Services on behalf of our Merchants;</li>
            <li>To contact you by email if you have subscribed to an Email Marketing Campaign;</li>
            <li>To track participation in a Loyalty Program;</li>
            <li>To provide customer support;</li>
            <li>To conduct our operations and for other general business purposes;</li>
            <li>
              To verify or maintain the quality and safety of our products and services and to repair, improve, upgrade,
              or enhance our services;
            </li>
            <li>
              To detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, and
              prosecute individuals responsible for that activity;
            </li>
            <li> To conduct audits and enable internal record keeping and administration of records;</li>
            <li> To personalize your experience when you use the Services;</li>
            <li>
              To track information about interactions with you, including, but not limited to, counting advertising
              impressions to unique visitors and verifying positioning and quality of advertising impressions;
            </li>
            <li> For statistical analysis; and</li>
            <li>
              For compliance with applicable laws and/or regulations and as otherwise required or permitted by
              applicable laws and/or regulations.
            </li>
          </ul>

          <TermsSubTitle>
            <a id="disclosePI" href="/#">
              How We Disclose the Personal Information We Collect
            </a>
          </TermsSubTitle>
          <TermsPlainText>
            We are a multinational business headquartered in the United States, with subsidiaries and affiliates in
            various countries around the world. We may disclose personal information about you to recipients located
            outside of your country, including our global subsidiaries and affiliates (see “Our Relationship with
            Affiliate Companies” below) as described in this Notice.
          </TermsPlainText>
          <TermsPlainText>
            For each category of personal information we collect, we may disclose such information in the manner and for
            the purposes described below:
          </TermsPlainText>
          <ul>
            <li>
              With companies engaged to help us run our business, subject to your consent if required by law. These
              types of companies may include our affiliate companies, service providers retained to perform functions on
              our behalf or to provide services to us, including (without limitation) legal, accounting, auditing,
              consulting, technology, security, and other professional service providers, and providers of other
              services related to our business. Portions of the Services may be provided by organizations with which
              Global Payments has a contractual relationship, including subcontractors, and, accordingly, your personal
              information may be disclosed to them;
            </li>
            <li>With third parties with whom you have a relationship, such as Merchants;</li>
            <li>With third parties in connection with promotions, should you enter a contest or promotion;</li>
            <li>With other third parties to whom you direct us to disclose personal information;</li>
            <li>
              With regulators including state and federal agencies and other parties required to enable compliance with
              laws, regulations and industry standards;
            </li>
            <li>
              In response to a court order or a request for cooperation from a regulatory, law enforcement or other
              government agency; to establish or exercise our legal rights; to defend legal claims; or as otherwise
              required or permitted by applicable laws and/or regulations;
            </li>
            <li>
              When we believe that disclosure is appropriate in connection with efforts to investigate, prevent, or take
              action regarding actual or suspected illegal activity, fraud, or other wrongdoing; to protect and defend
              the rights, property or safety of Global Payments, its customers, staff, suppliers, affiliates, or others;
              and
            </li>
            <li>
              To third parties in connection with any proposed or actual reorganization, merger, sale, joint venture,
              assignment, transfer or other disposition of all or any portion of our assets or stock (including in
              connection with any bankruptcy or similar proceedings).
            </li>
          </ul>

          <TermsSubTitle>
            <a id="affiliateCompanies" href="/#">
              Our Relationship with Affiliate Companies
            </a>
          </TermsSubTitle>
          <TermsPlainText>
            Subject to your consent if required by applicable law, we may appoint an affiliate company to process
            personal information in a service provider role. In most cases, we or the Merchant will remain responsible
            for that company’s processing of personal information pursuant to applicable data privacy laws. Because our
            affiliate companies are located around the globe, personal information about you may be transferred to and
            stored in the United States or in another country outside of the country in which you reside, which may be
            subject to different standards of data protection than your country of residence. We take appropriate steps
            to transfer personal information in accordance with applicable law.
          </TermsPlainText>

          <TermsSubTitle>
            <a id="financial" href="/#">
              Notice of Financial Incentive / Bona Fide Loyalty Program
            </a>
          </TermsSubTitle>
          <TermsPlainText>
            One of the Services we offer on behalf of our Merchants is the ability to create loyalty programs. The
            Loyalty Programs may provide certain perks, such as rewards and exclusive offers. The Loyalty Programs may
            also provide promotional campaigns alone or in conjunction with third party partners. Importantly, the{' '}
            <a href={`https://${termsUrl}`}>terms and conditions</a> of the Loyalty Program are set by the Merchant.
            When you sign up for a Loyalty Program through one of our Merchants, we typically ask for your contact
            information (i.e. telephone number). Because the Loyalty Programs involve the collection of personal
            information they may be interpreted as a “financial incentive” under California law. The value of your
            personal information is determined by the Merchant in relation to the value of the free or discounted
            products, services, or other benefits provided as part of the Loyalty Program, less the expense related to
            the offering of those products, services, or other benefits to Loyalty Program participants.
          </TermsPlainText>
          <TermsPlainText>
            You may opt-in to a Loyalty Program by providing your telephone number when prompted on a POS system at a
            participating Merchant and following the registration steps sent via SMS thereafter. Payment card-linked
            functionality may also be used in order to provide a Loyalty Program on behalf of Merchants. You may
            withdraw or opt out of a Loyalty Program by clicking on the “Opt-Out of Loyalty Program” hyperlink in the
            SMS message(s) or by contacting the Merchant directly, as described further in the “Managing Your
            Preferences” section, below. If you opt to exercise a right to delete your personal data (see “Your Legal
            Rights” below), that request may make it impossible to participate in a Loyalty Program or to receive any of
            the associated benefits.
          </TermsPlainText>
          <TermsPlainText>
            Please refer to the “Personal Information We Collect” and the “How We Use the Personal Information We
            Collect” sections above for details of the categories of personal data we obtain and third parties to whom
            we may disclose personal data as it relates to a Loyalty Program.
          </TermsPlainText>

          <TermsSubTitle>
            <a id="managingPreferences" href="/#">
              Managing Your Preferences
            </a>
          </TermsSubTitle>
          <TermsPlainText>
            If you join a Merchant’s Loyalty Program by providing your phone number, we may use such personal
            information to send you an SMS message to complete the registration process. The original registration SMS
            message will contain a link to “Opt-out of Loyalty Program.” You can follow that hyperlink to opt-out of the
            Loyalty Program. You may also withdraw from a Loyalty Program by contacting the Merchant directly.
          </TermsPlainText>
          <TermsPlainText>
            If you subscribe to a Merchant’s Email Marketing Campaign by providing your email address, we may use such
            personal information to send you promotional emails about the Merchant’s products and services. We will take
            steps to ensure that any promotional emails which are sent by electronic means provide a simple means for
            you to stop further communications, in accordance with applicable law. You will have an opportunity to
            unsubscribe from receiving promotional materials through the Service by clicking on an “unsubscribe”
            hyperlink contained in promotional emails we send you. We will process your request as soon as possible, but
            you may continue to receive promotional emails after you submit an unsubscribe request until it is processed
            in our systems. Please note that if you opt out of receiving marketing-related communications, we may still
            send you administrative, transactional, or information messages, from which you cannot opt out.
          </TermsPlainText>

          <TermsSubTitle>
            <a id="legalRights" href="/#">
              Your Legal Rights
            </a>
          </TermsSubTitle>
          <TermsPlainText>
            If you are a Customer who interacts with the Services in connection with a Merchant and have questions about
            legal rights you may have with respect to personal information collected in connection with the Services we
            provide on behalf of the Merchant, please consult the Merchant with whom you have a relationship.
          </TermsPlainText>
          <TermsPlainText>
            Subject to certain exceptions, and in some cases dependent upon the processing activity involved, you may
            have the right to:
          </TermsPlainText>
          <ul>
            <li>Know whether we process personal information about you;</li>
            <li>Know how the personal information is used;</li>
            <li>Access, request and receive the personal information we have collected in a portable manner;</li>
            <li>
              Opt out of having personal information sold, “shared,” or (in certain circumstances) used for certain
              profiling activities;
            </li>
            <li>Request that we correct inaccuracies in personal information about you; and</li>
            <li>Request that we delete personal information about you.</li>
          </ul>
          <TermsPlainText>
            You may contact us using the information in the “Contact Us” section of this Notice for additional
            information about how to exercise your rights. In addition, if you are a resident of California, Colorado,
            or other jurisdiction with similar laws, please see the “Region-Specific Information” section below for
            further details on how to exercise your privacy rights.
          </TermsPlainText>

          <TermsSubTitle>
            <a id="dataSecurity" href="/#">
              Data Security and Data Retention
            </a>
          </TermsSubTitle>
          <TermsPlainText>
            We maintain administrative, technical, and physical safeguards designed to protect the personal information
            you provide via the Services against accidental, unlawful or unauthorized destruction, loss, alteration,
            access, disclosure, or use.
          </TermsPlainText>
          <TermsPlainText>
            Any suspected attempt to breach our policies and procedures, or to engage in any type of unauthorized action
            involving our information systems, is regarded as potential criminal activity. Suspected computer mischief
            may be reported to the appropriate authorities.
          </TermsPlainText>
          <TermsPlainText>
            Please remember that communications over the internet such as emails are not secure. We seek to keep secure
            personal information submitted to us through the Services in accordance with our obligations under
            applicable laws and regulations. However, we cannot guarantee the security of any data transmitted through
            the Services.
          </TermsPlainText>
          <TermsPlainText>
            We retain the personal information we collect as part of the Services for different periods of time
            depending on what it is and how we use it, but only for as long as is necessary to complete the legitimate
            business or legal purposes for which we collected it. The criteria used to determine our retention periods
            include:
          </TermsPlainText>
          <ul>
            <li>
              The length of time we have an ongoing relationship with you and provide the Services to you on behalf of
              the Merchant(s), for example, for as long as you have an account or continue to use our Services, and the
              length of time thereafter during which we may have a legitimate need to reference personal information to
              address issues that may arise;
            </li>
            <li>
              Whether there is a contractual obligation to which we are subject, for example, our contracts with our
              Merchants may specify a certain period of time during which we are required to maintain the information on
              behalf of our Merchants;
            </li>
            <li>
              Whether there is a legal obligation to which we are subject, for example, certain laws require us to keep
              records of your transactions for a certain period of time before we can delete them; and
            </li>
            <li>
              Whether retention is advisable in light of our legal position, such as in regard to applicable statutes of
              limitations, litigation or regulatory investigations.
            </li>
          </ul>

          <TermsSubTitle>
            <a id="cookies" href="/#">
              Cookies and Other Tracking Technologies
            </a>
          </TermsSubTitle>
          <TermsPlainText>
            Cookies or similar tracking technologies may be used as part of Email Marketing Campaigns to help us
            remember information about your use of the Services, like your country, language and other settings. They
            can also help us to provide the Services more efficiently. Cookies and other tracking technologies can allow
            us to do various other things, as explained further below.
          </TermsPlainText>
          <TermsPlainText>
            The cookies and tracking technologies that we may use in the Services, particularly through an Email
            Marketing Campaign, fall into the following categories:
          </TermsPlainText>
          <TermsPlainText>
            <i>Strictly Necessary.</i> These cookies are necessary for the Services to function and cannot be switched
            off in our systems. They are usually only set in response to actions taken by you such as filling in forms.
            You can set your browser to block or alert you about these cookies, but blocking them may impede the
            functionality of the Services.
          </TermsPlainText>
          <TermsPlainText>
            <i>Performance.</i> These cookies allow us to count visits and traffic sources so we can measure and improve
            the performance of the Services. They help us to know which pages are the most and least popular and see how
            visitors interacted with the Services. All information these cookies collect is aggregated. If you do not
            allow these cookies we will not know when you have visited or interacted with the Services, and will not be
            able to monitor performance.
          </TermsPlainText>
          <TermsPlainText>
            <i>Functionality.</i> These cookies enable the Services to provide enhanced functionality and
            personalization. They may be set by us or by third-party providers whose services we have added to our
            pages. If you do not allow these cookies, then some of these Services may not function properly.
          </TermsPlainText>
          <TermsPlainText>
            <u>How to Delete or Block Cookies and Other Tracking Technologies.</u> We do not currently respond to
            browser Do-Not-Track signals. On some Services, when technically feasible, we will enable tools to help you
            make choices about cookies and other tracking technologies. Most browsers allow you to automatically decline
            cookies or be given the choice of declining or accepting a particular cookie (or cookies) from a particular
            website. You may also refer to http://www.allaboutcookies.org/manage-cookies/index.html. If, however, you do
            not accept cookies, you may experience some inconvenience in your use of the Services. You also may not
            receive advertising from us that is relevant to your interests and needs.
          </TermsPlainText>

          <TermsSubTitle>
            <a id="externalLinks" href="/#">
              External Links
            </a>
          </TermsSubTitle>
          <TermsPlainText>
            We may provide links through our Services, particularly in promotional emails that we send on behalf of
            Merchants, to third-party websites or services that are not under our control. We do not endorse or make any
            warranty of any type regarding the content contained on such third-party websites or services or regarding
            the products and services they offer. We make no representation regarding your use of such websites or
            services. Please be aware that we are not responsible for the privacy practices of the operators of other
            websites or services. We encourage our users to be aware when they visit other websites or services. You
            should read any other applicable privacy and cookies notices carefully before accessing and using
            third-party websites or services.
          </TermsPlainText>

          <TermsSubTitle>
            <a id="children" href="/#">
              Children
            </a>
          </TermsSubTitle>
          <TermsPlainText>
            The Services are not directed to children or intended for use by children. We do not solicit or knowingly
            accept any personal information from persons under the age of 18. Please do not use the Services if you are
            under the age of 18.
          </TermsPlainText>

          <TermsSubTitle>
            <a id="changesAndUpdates" href="/#">
              Changes and Updates
            </a>
          </TermsSubTitle>
          <TermsPlainText>
            We reserve the right, in our sole discretion, to modify, update, or otherwise change this Notice. The “Last
            Updated” legend at the top of this Notice indicates when this Notice was last revised. Any changes will
            become effective when we post the revised Notice on our Services.
          </TermsPlainText>

          <TermsSubTitle>
            <a id="contactUs" href="/#">
              Contact Us
            </a>
          </TermsSubTitle>
          <TermsPlainText>
            If you have questions about this Notice, or if you want to exercise your rights as described in this Notice,
            you can submit a request by completing <a href={'privacy@globalpay.com'}>this form</a> or you may contact us
            as follows:
          </TermsPlainText>
          <TermsPlainText>
            <div>Global Payments Inc.</div>
            <div>3550 Lenox Rd NE, Suite 3000</div>
            <div>Atlanta, GA 30326</div>
            <div>
              <a href={'privacy@globalpay.com'}>privacy@globalpay.com</a>
            </div>
            <div>800-367-2638</div>
          </TermsPlainText>
          <TermsPlainText>
            If you make a request to exercise a privacy right, we will verify and respond to your request consistent
            with applicable law, taking into account the type and sensitivity of the personal information subject to the
            request. We may decline to honor your request where an exception applies. In order to honor any access or
            deletion request, we will require you to provide enough information for us to verify your identity. For
            example, we may ask you for information associated with your account, including your contact information or
            other identifying information. If you would like your agent to make a request on your behalf, if permitted
            under applicable law, the agent may exercise those rights as noted above. We will process the agent’s
            request consistent with applicable law. As part of our verification process, we may request that the agent
            provide, as applicable, proof concerning their status as an authorized agent. In addition, we may require
            that you verify your identity as described in this section or confirm that you provided the agent permission
            to submit the request.
          </TermsPlainText>

          <TermsSubTitle>
            <a id="RegionSpecificInformation" href="/#">
              Region-Specific Information
            </a>
          </TermsSubTitle>
          <TermsPlainText>
            <u>Information for Residents of California, Colorado, and Other Similar Jurisdictions</u>
          </TermsPlainText>

          <TermsPlainText>
            This section supplements the above Notice and further describes how we collect, use, and disclose the
            personal information we collect about residents of California, Colorado, and other similar jurisdictions.
          </TermsPlainText>
          <TermsPlainText style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
            Categories of Personal Information We Collect and Our Purposes for Collection, Use, and Disclosure
          </TermsPlainText>
          <TermsPlainText>
            The following chart details which categories of personal information we collect and process, as well as
            which categories of personal information we disclose to third parties for our operational business purposes,
            including within the preceding 12 months.
          </TermsPlainText>

          <StyledTable>
            <tr>
              <BorderedTableCellBold>
                Categories of Personal Information (See the “Personal Information We Collect” section for a description
                of each category of Personal Information)
              </BorderedTableCellBold>
              <BorderedTableCell>
                <b>Disclosed to Which Categories of Third Parties for Operational Business Purposes</b>
              </BorderedTableCell>
              <BorderedTableCellBold>Processing Purposes</BorderedTableCellBold>
            </tr>
            <tr>
              <BorderedTableCell>
                Basic Identifying Information; Device Information and Other Unique Identifiers; Internet or Other
                Network Activity; Commercial Information; Information You Provide; Inferences
              </BorderedTableCell>
              <BorderedTableCell>
                Please see the “How We Disclose the Personal Information We Collect” section for a list of the
                categories of third parties to whom we may disclose these categories of personal information.
              </BorderedTableCell>
              <BorderedTableCell>
                Please see the “How We Use the Personal Information We Collect” section for a list and description of
                the applicable processing purposes for these categories of personal information.
              </BorderedTableCell>
            </tr>
            <tr>
              <BorderedTableCell>Payment Information</BorderedTableCell>
              <BorderedTableCell>
                Affiliates; third parties with whom you have a relationship (i.e. Merchants); regulators; legal
                authorities and other third parties in order to comply with laws, regulations, and standards{' '}
              </BorderedTableCell>
              <BorderedTableCell>
                <ul>
                  <li>Provide the Services and customer support</li>
                  <li>Operations and general business purposes</li>
                  <li>Fraud prevention and security</li>
                  <li>Legal and compliance</li>
                </ul>
              </BorderedTableCell>
            </tr>
          </StyledTable>

          <TermsPlainText style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
            Sales and Shares of Personal Information for Cross-Context Behavioral or Targeted Advertising
          </TermsPlainText>
          <TermsPlainText>
            We do not sell your personal information to third parties for monetary compensation in connection with the
            Services. We do not knowingly sell or “share” (for purposes of cross-context behavioral advertising) the
            personal information, including the sensitive personal information, of minors under 16 years of age.
          </TermsPlainText>
          <TermsPlainText>
            To the extent that we share your personal information with third parties for cross-context behavioral
            advertising, we will implement features within the services that enable you to see a list of the entities
            with which the personal information is shared and which enable you to express your preferences. You can find
            additional information about how those third parties use personal information in the applicable third-party
            privacy notice.
          </TermsPlainText>

          <TermsPlainText style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
            Collection, Processing, and Disclosure of Sensitive Personal Information
          </TermsPlainText>
          <TermsPlainText>
            Subject to your consent where required by applicable law, we collect, process, and disclose sensitive
            personal information for purposes of: providing goods or services as requested; ensuring safety, security,
            and integrity; countering wrongful or unlawful actions; short term transient use such as displaying first
            party, non-personalized advertising; performing services for our business, including maintaining and
            servicing accounts, providing customer service, processing or fulfilling orders and transactions, verifying
            customer information, processing payments, providing financing, providing analytic services, providing
            storage, or providing similar services on behalf of our business; activities relating to quality and safety
            control or product improvement; and other collection and processing that is not for the purpose of inferring
            characteristics about an individual. We do not use sensitive personal information beyond these purposes.
          </TermsPlainText>
          <TermsPlainText style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Rights and Requests</TermsPlainText>

          <StyledTable>
            <tr>
              <BorderedTableCellBold>
                Right to know about personal information collected, used, disclosed, “shared,” and sold
              </BorderedTableCellBold>
              <BorderedTableCell>
                <TermsPlainText>
                  You have the right to know whether we process personal information about you, and to access such
                  personal information. If you are a California resident, you may also request that we disclose to you:
                </TermsPlainText>
                <ul>
                  <li>
                    the categories of personal information we have collected about you and the categories of sources
                    from which we collected such personal information;
                  </li>
                  <li>
                    the business or commercial purpose for which we collected, “shared,” or sold the personal
                    information about you;
                  </li>
                  <li>
                    the categories of personal information about you that we “shared” or sold and the categories of
                    third parties with whom we “shared” or to whom we sold the personal information about you; and
                  </li>
                  <li>
                    the categories of personal information about you that we disclosed for a business purpose, and the
                    categories of third parties to whom we disclosed that information for a business purpose.
                  </li>
                </ul>
              </BorderedTableCell>
            </tr>
            <tr>
              <BorderedTableCellBold>Right to receive a copy of personal information</BorderedTableCellBold>
              <BorderedTableCell>
                You have the right to request that we provide the specific pieces of personal information, including a
                copy of such personal information in a portable format.
              </BorderedTableCell>
            </tr>
            <tr>
              <BorderedTableCellBold>Right to opt-out of the sale of personal information</BorderedTableCellBold>
              <BorderedTableCell>
                You may request that we do not sell personal information about you to third parties.
              </BorderedTableCell>
            </tr>
            <tr>
              <BorderedTableCellBold>
                Right to opt-out of targeted advertising, including the “sharing” of personal information for
                cross-context behavioral advertising
              </BorderedTableCellBold>
              <BorderedTableCell>
                You may request that we opt you out of targeted advertising, including that we stop “sharing” personal
                information about you for purposes of cross-context behavioral advertising. See the “Sales and Shares of
                Personal Information for Cross-Context Behavioral or Targeted Advertising” section for more information.
              </BorderedTableCell>
            </tr>
            <tr>
              <BorderedTableCellBold>Right to request deletion</BorderedTableCellBold>
              <BorderedTableCell>
                In some circumstances, you have the right to have personal information about you deleted.
              </BorderedTableCell>
            </tr>
            <tr>
              <BorderedTableCellBold>Right to equal service and prices (“non-discrimination”)</BorderedTableCellBold>
              <BorderedTableCell>
                Your choice to exercise your privacy rights will not be used as a basis to discriminate against you in
                services offered or pricing.
              </BorderedTableCell>
            </tr>
            <tr>
              <BorderedTableCellBold>Right to request correction</BorderedTableCellBold>
              <BorderedTableCell>
                You have the right to request that we correct inaccuracies in your personal information.
              </BorderedTableCell>
            </tr>
            <tr>
              <BorderedTableCellBold>
                Right to limit the use and disclosure of sensitive personal information
              </BorderedTableCellBold>
              <BorderedTableCell>
                In some circumstances, you may request that we limit the use and disclosure of your sensitive personal
                information.
              </BorderedTableCell>
            </tr>
            <tr>
              <BorderedTableCellBold>Right to appeal</BorderedTableCellBold>
              <BorderedTableCell>
                Depending on your state of residence, if we refuse to take action on your request, you may appeal this
                refusal within a reasonable period after you have received notice of the refusal. To appeal, you can
                contact us at privacy@globalpay.com or complete this form and select “Appeal a decision about a previous
                request” under “Type of Request.”
              </BorderedTableCell>
            </tr>
          </StyledTable>

          <TermsPlainText>
            You can submit a request for any of the above rights by using the contact information in the “Contact Us”
            section of this Notice.{' '}
          </TermsPlainText>

          <TermsPlainText style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
            California Shine the Light
          </TermsPlainText>

          <TermsPlainText>
            If you are a resident of California, you may request information concerning the categories of personal
            information (if any) we share with third parties or affiliates for their direct marketing purposes. If you
            would like more information as it relates to the Merchant’s activities, please contact the Merchant
            directly. If you are seeking more information about our practices, please submit a written request to us
            using the information provided in the “Contact Us” section of this Notice.
          </TermsPlainText>

          <TermsPlainText></TermsPlainText>
        </TermsPlainTextWrapper>
      </TermsTextWrapper>
    </PageWithLoader>
  );
};

import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

export enum TranslationNamespace {
  Common = 'Common',
  EmOptout = 'EmOptout'
}

// prettier-ignore
export function changeLanguage(locale: string) {
  const localeToLngMap: Record<string, string> = {
    'en-US': 'en-US',
    'en_US': 'en-US',
    'fr-CA': 'fr-CA',
    'fr_CA': 'fr-CA',
    'en-GB': 'en-GB',
    'en_GB': 'en-GB',
    'de-DE': 'de-AT',
    'de_DE': 'de-AT',
    'cs-CZ': 'cs',
    'cs_CZ': 'cs',
    'ro-RO': 'ro',
    'ro_RO': 'ro',
    'sk-SK': 'sk',
    'sk_SK': 'sk'
  };
  const lng = localeToLngMap[locale];
  if (i18nInstance.languages.find(existingLng => existingLng === lng)) i18nInstance.changeLanguage(lng);
}

const opts: InitOptions = {
  fallbackLng: {
    'fr-CA': ['en-US'],
    'de-AT': ['en-GB'],
    cs: ['en-GB'],
    ro: ['en-GB'],
    sk: ['en-GB'],
    default: ['en-US']
  },
  preload: false,
  load: 'currentOnly',
  debug: true,
  ns: [],
  defaultNS: TranslationNamespace.Common,
  saveMissing: false
};

const i18nInstance = i18n.createInstance(opts);

i18nInstance
  .use(resourcesToBackend((lng: string, ns: string) => import(`../public/translations/${lng}/${ns}.json`)))
  .use(initReactI18next)
  .init();

export default i18nInstance;

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchData, selectMerchantInfo } from '../../../../store/termsAndConditions/termsAndConditionsSlice';
import { useSearchParams } from 'react-router-dom';
import { CZTerms } from './CZTerms';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { PageWithLoader } from '../../../PageWithLoader';

export const DanubeTermsAndConditionsComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const merchantInfo = useSelector(selectMerchantInfo);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  useEffect(() => {
    const account: string = searchParams.get('account') as string;
    const jwt: string = searchParams.get('jwt') as string;

    if (account && jwt) {
      dispatch(fetchData({ account, jwt }));
    }
  }, [dispatch, searchParams, setSearchParams]);

  // Will be refactored to use text regarding locale
  return (
    <PageWithLoader>
      <CZTerms {...merchantInfo} />
    </PageWithLoader>
  );
};
